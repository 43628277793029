import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { ModalWrapper } from './Modal.elements'

const Modal = (props) => {
  // Destructure
  const { children, ...rest } = props

  return <ModalWrapper {...rest}>{children}</ModalWrapper>
}

// Default Props
Modal.defaultProps = {
  children: '',
}

// Proptypes Validation
Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
}

export default Modal
