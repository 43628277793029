import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoggedIn: true,
}

const store = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateRole: (state) => {
      state.role = 'dev'
    },
  },
})

export default store
