import styled from 'styled-components'

export const CasesWrapper = styled.div`
  width: 100%;
  height: fit-content;
  margin: 100px 0px 0px 0px;
`

export const CaseBreakdownWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin: 40px 0px 0px 0px;
  border-radius: 5px;

  // Responsive Properties
  ${({ theme }) =>
    theme.functions.mediaQuery('border', [
      'none',
      'none',
      'none',
      'none',
      'none',
      `1px solid ${theme.colors.lightGrey}`,
    ])}
  ${({ theme }) =>
    theme.functions.mediaQuery('background-color', [
      'transparent',
      'transparent',
      'transparent',
      'transparent',
      'transparent',
      `${theme.colors.white}`,
    ])}
`

export const CaseBreakdownItem = styled.div`
  padding: 20px 20px 0 20px;

  // Responsive Properties
  ${({ theme }) => theme.functions.mediaQuery('flex', ['none', 'none', 'none', 'none', 'none', '1'])}
  ${({ theme }) => theme.functions.mediaQuery('width', ['100%', '100%', '100%', '100%', '100%', 'unset'])}
  ${({ theme }) => theme.functions.mediaQuery('border-radius', ['5px', '5px', '5px', '5px', '5px', '0px'])}
  ${({ theme }) =>
    theme.functions.mediaQuery('margin', [
      '0px 0px 20px 0px',
      '0px 0px 20px 0px',
      '0px 0px 20px 0px',
      '0px 0px 20px 0px',
      '0px 0px 20px 0px',
      '0px',
    ])}
  ${({ theme }) =>
    theme.functions.mediaQuery('border', [
      `1px solid ${theme.colors.lightGrey}`,
      `1px solid ${theme.colors.lightGrey}`,
      `1px solid ${theme.colors.lightGrey}`,
      `1px solid ${theme.colors.lightGrey}`,
      `1px solid ${theme.colors.lightGrey}`,
      'none',
    ])}
  ${({ theme }) =>
    theme.functions.mediaQuery('background-color', [
      theme.colors.white,
      theme.colors.white,
      theme.colors.white,
      theme.colors.white,
      theme.colors.white,
      'none',
    ])}
`

export const CaseBreakdownTableWrapper = styled.div`
  // Responsive Properties
  ${({ theme }) =>
    theme.functions.mediaQuery('padding', [
      '20px 0px 0px 0px',
      '20px 0px 0px 0px',
      '20px 0px 0px 0px',
      '20px 0px 0px 0px',
      '20px 0px 0px 0px',
      '40px 0px 0px 0px',
    ])}
`
