import { combineReducers, bindActionCreators } from 'redux'

import Auth from './Auth'
import UI from './UI'
import Form from './Form'
import Investigation from './Investigation'
import Alert from './Alert'
import Filters from './Filters'

const actionList = {
  ...Auth.actions,
  ...UI.actions,
  ...Form.actions,
  ...Investigation.actions,
  ...Alert.actions,
  ...Filters.actions,
}

const reducerList = {
  auth: Auth.reducer,
  ui: UI.reducer,
  form: Form.reducer,
  investigation: Investigation.reducer,
  alert: Alert.reducer,
  filters: Filters.reducer,
}

export const reducers = combineReducers(reducerList)

export const actions = (dispatch) => ({
  actions: bindActionCreators(actionList, dispatch),
})
