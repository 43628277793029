import styled from 'styled-components'

export const AlertStatusWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  width: 100%;
`
export const AlertStatusContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 62px;

  padding: 10px 10px 0px 10px;
  border-radius: 5px;

  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.darkGray};
  border: ${({ theme }) => `solid 1px ${theme.colors.lightGrey}`};
`
export const AlertStatusTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
`
export const AlertStatusNumber = styled.div`
  font-size: 30px;
  font-weight: 500;
`
export const AlertStatusSpan = styled.div`
  font-size: 12px;
  position: absolute;
  bottom: 7px;
  right: 7px;

`
