import React from 'react'
import PropTypes from 'prop-types'

// Views
import { LineChart } from 'views/components'

// Styled Elements
import { CaseManagementTableChartWrapper } from './CaseManagementTableChart.elements'

const CaseManagementTableChart = (props) => {
  // Destructure
  const { data, totalCount } = props

  return (
    <CaseManagementTableChartWrapper>
      {totalCount}
      <LineChart
        data={data}
        height={40}
        width={150}
        options={{
          responsive: true,
          legend: {
            display: false,
          },
          tooltips: {
            enabled: false,
          },
          layout: {
            padding: {
              bottom: 14,
            },
          },
          scales: {
            xAxes: [
              {
                type: 'time',
                gridLines: {
                  color: '#fff',
                  display: false,
                  lineWidth: 0,
                },
                display: false,
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  color: '#fff',
                  display: false,
                  lineWidth: 0,
                },
                ticks: {
                  display: false,
                  beginAtZero: true,
                  min: 0,
                },
              },
            ],
          },
        }}
      />
    </CaseManagementTableChartWrapper>
  )
}

// Default Props
CaseManagementTableChart.defaultProps = {
  data: {},
  totalCount: 0,
}

// Proptypes Validation
CaseManagementTableChart.propTypes = {
  data: PropTypes.shape({ datasets: PropTypes.instanceOf(Array), labels: PropTypes.instanceOf(Array) }),
  totalCount: PropTypes.number,
}

export default CaseManagementTableChart
