import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { InputWrapper, Label, TextAreaInput, SaveLoaderWrapper, SaveLoaderTitle } from './TextArea.elements'

// Views
import { Image } from 'views/components'

// Assets
import SaveIcon from 'assets/images/save.svg'

const TextArea = (props) => {
  // Destructure
  const { type, label, resize, saveLoading, ...rest } = props

  return (
    <InputWrapper>
      {label && <Label>{label}</Label>}
      <TextAreaInput type={type} resize={resize} {...rest} />
      {saveLoading && (
        <SaveLoaderWrapper>
          <SaveLoaderTitle>
            Saving...
          </SaveLoaderTitle>
          <Image src={SaveIcon} width={16} height={16} />
        </SaveLoaderWrapper>
      )}
    </InputWrapper>
  )
}

// Default Props
TextArea.defaultProps = {
  type: '',
  label: '',
  resize: true,
  saveLoading: false,
}

// Proptypes Validation
TextArea.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  resize: PropTypes.bool,
  saveLoading: PropTypes.bool,
}

export default TextArea
