import React from 'react'
import PropTypes from 'prop-types'
import { Line } from 'react-chartjs-2'

// Styled Elements
import { LineChartWrapper } from './LineChart.elements'

const LineChart = (props) => {
  // Destructure
  const { data, width, height, options } = props

  return (
    <LineChartWrapper>
      <Line data={data} width={width} height={height} options={options} />
    </LineChartWrapper>
  )
}

// Default Props
LineChart.defaultProps = {
  data: {},
  width: 200,
  height: 90,
  options: {},
}

// Proptypes Validation
LineChart.propTypes = {
  data: PropTypes.shape({ root: PropTypes.string }),
  width: PropTypes.number,
  height: PropTypes.number,
  options: PropTypes.shape({ root: PropTypes.string }),
}

export default LineChart
