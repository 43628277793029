import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

// Styled Elements
import {
  FormInputGroupWrapper,
  FormInputGroupItem,
  ProfileAccountInformationFormWrapper,
  FormLabel,
} from './ProfileAccountInformationForm.elements'

// Views
import { Form, FormTextField, Button } from 'views/components'

const ProfileAccountInformationForm = (props) => {
  // Destructure
  const { userProfileData } = props
  const { User_ID, User_Name } = userProfileData

  // Variables
  const initialValues = {
    userId: User_ID,
    userName: User_Name,
  }

  const validationSchema = Yup.object().shape({
    userId: Yup.string().required('User ID is required'),
    userName: Yup.string().required('User Name is required'),
  })

  // Functions
  const handleOnSubmit = () => {}

  return (
    <ProfileAccountInformationFormWrapper>
      <Form initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
        <FormInputGroupWrapper>
          <FormInputGroupItem>
            <FormTextField label="User Id" type="text" name="userId" disabled />
          </FormInputGroupItem>
          <FormInputGroupItem>
            <FormTextField label="User Name" type="text" name="userName" disabled />
          </FormInputGroupItem>
          <FormInputGroupItem>
            <FormLabel>Password</FormLabel>
            <Button>
              <NavLink to="reset-password">Change Password</NavLink>
            </Button>
          </FormInputGroupItem>
        </FormInputGroupWrapper>
      </Form>
    </ProfileAccountInformationFormWrapper>
  )
}

// Default Props
ProfileAccountInformationForm.defaultProps = {
  userProfileData: {},
}

// Proptypes Validation
ProfileAccountInformationForm.propTypes = {
  userProfileData: PropTypes.shape({
    User_ID: PropTypes.string,
    User_Name: PropTypes.string,
  }),
}

export default ProfileAccountInformationForm
