import React from 'react';
import PropTypes from 'prop-types'
import { HorizontalBar } from 'react-chartjs-2';

// Styled Elements
import { HorizontalBarChartWrapper } from './HorizontalBarChart.elements'

const HorizontalBarChart = (props) => {
	const { data, options } = props

  return (
    <HorizontalBarChartWrapper>
      <HorizontalBar options={options} data={data} />
    </HorizontalBarChartWrapper>
	)
}

// Default Props
HorizontalBarChart.defaultProps = {
  data: {},
  options: {},
}

// Proptypes Validation
HorizontalBarChart.propTypes = {
  data: PropTypes.shape({ root: PropTypes.string }),
  options: PropTypes.shape({ root: PropTypes.string }),
}

export default HorizontalBarChart
