import React, { useState, useEffect, useRef, createRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Helpers
import { handleUpdateEvent, handleOnInitialLoad } from './InvestigationChartHelpers'

// Views
import { NodeChart } from 'views/components'

// Map Redux Props
const mapStateToProps = ({ investigation }) => ({ investigation })
const mapDispatchToProps = actions

const InvestigationChart = (props) => {
  // Destructure
  const { investigation, actions } = props

  // Refs
  const nodeChartRef = createRef()
  const nodeChartInstance = useRef()

  // Store State
  const { initialNodeData } = investigation

  // Store Actions
  const { setShowHeaderLoader, showAlert } = actions

  // State
  const [isChartLoaded, setIsChartLoaded] = useState(false)
  const [chartOptions, setChartOptions] = useState({})

  // Functions
  const handleOnChartClick = (
    { value: { chain, direction, hash, riskScore, type, children, uniqueID }, data, seriesType, event }, echart
  ) => {
    if (data.children.length) return

    // Show loading feedback when fetching children data
    echart.showLoading('default', { color: '#777' })

    if (event.type === 'click' && seriesType === 'tree') {
      // Fetch children data
      handleUpdateEvent({ direction, hash, riskScore, children, type, chain, uniqueID }, echart, showAlert)
    }
  }

  const handleOnLegendSelectChange = ({ type, name }, echart) => {
    // Validate if event is from tree legend
    if (type === 'legendselectchanged') {
      // Show either incoming/outgoing chart on select
      echart.setOption({
        ...echart.getOption(),
        legend: [
          {
            ...echart.getOption().legend,
            selected: {
              incoming: name !== 'outgoing',
              outgoing: name !== 'incoming',
            },
          },
        ],
      })
    }
  }

  // useEffect
  useEffect(() => {
    handleOnInitialLoad({ initialNodeData, setChartOptions, setIsChartLoaded }, showAlert)
  }, [])

  useEffect(() => {
    if (!isChartLoaded) {
      setShowHeaderLoader(true)
    } else {
      setShowHeaderLoader(false)
    }
  }, [isChartLoaded])

  useEffect(() => {
    if (nodeChartRef.current) {
      nodeChartInstance.current = nodeChartRef.current
    }
  }, [nodeChartRef])

  return (
    isChartLoaded && (
      <NodeChart
        chartRef={nodeChartRef}
        style={{
          height: '100vh',
          width: '100vw',
        }}
        options={chartOptions}
        onEvents={{
          click: handleOnChartClick,
          legendselectchanged: handleOnLegendSelectChange,
        }}
      />
    )
  )
}

// Default Props
InvestigationChart.defaultProps = {
  investigation: {},
  actions: {},
}

// Proptypes Validation
InvestigationChart.propTypes = {
  investigation: PropTypes.shape({
    initialNodeData: PropTypes.instanceOf(Object),
  }),
  actions: PropTypes.shape({
    setInvestigationAlert: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    showAlert: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(InvestigationChart)
