import { createMuiTheme } from '@material-ui/core'

const theme = createMuiTheme({})

// Theme Overrides
theme.overrides = {
  MuiButton: {
    root: {
      backgroundColor: 'green',
      textTransform: 'none',
      minWidth: 120,
      borderRadius: 5,
    },
    label: {
      fontSize: '16px',
      fontWeight: 'inherit',
    },
  },
  MuiOutlinedInput: {
    root: {
      '&&& $input': {
        padding: 0,
      },
    },
  },
  MuiAutocomplete: {
    root: {
      backgroundColor: '#fff',
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #ced4da',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #ced4da',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #ced4da',
      },
    },
    popper: {
      padding: '0px !important',
      boxShadow: 'none !important',
    },
    listbox: {
      padding: 0,
    },
    option: {
      paddingTop: 12,
      paddingLeft: 16,
      paddingRight: 16,
      paddingBottom: 12,
    },
    inputRoot: {
      '&&[class*="MuiOutlinedInput-root"] $input': {
        padding: 0,
        '&:first-child': {
          padding: '6px 4px',
          height: '100%',
        },
      },
    },
  },
  MuiPaper: {
    elevation1: {
      boxShadow: 'none',
      border: '1px solid #ced4da',
    },
  },
}

// Default Component Props
theme.props = {
  MuiButton: {
    disableElevation: true,
    disableRipple: true,
    disableFocusRipple: true,
  },
  MuiTextField: {
    InputLabelProps: {
      shrink: false,
    },
  },
}

export default theme
