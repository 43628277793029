import styled from 'styled-components'

// Assets
import CalendarIcon from 'assets/images/icon-calendar.svg'

export const DateRangePickerWrapper = styled.div`
  font-family: Roboto;
  font-size: 18px;

  // Themed Properties
  color: ${({ theme }) => theme.colors.gray};
`

export const DateRangeHolder = styled.div`
  position: relative;

  display: flex;
  padding: 8px 50px 7px 15px;
  width: fit-content;
  height: 50px;

  border-radius: 5px;

  cursor: pointer;

  // TODO: Use themed functions
  &:after {
    content: '';

    position: absolute;
    top: 50%;
    right: 15px;

    transform: translateY(-50%);

    width: 15px;
    height: 15px;

    background-image: url(${CalendarIcon});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  // Themed Properties
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
`

export const DateRangeTextWrapper = styled.p`
  font-size: 14px;
  margin: 7px 0px 0px 0px;

  // TODO: Use themed functions
  &:first-child {
    margin-right: 10px;
  }
`

export const DateRangeText = styled.span`
  // TODO: Use themed functions
  &:first-child {
    font-weight: 500;
    margin: 0px 5px 0px 0px;
  }
`
