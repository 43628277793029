import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'
import { useUpdatePassword } from 'core/hooks/api'

// Styled Elements
import {
  ResetPasswordFormWrapper,
  ResetPasswordFormLabel,
  FormInputGroupWrapper,
  FormInputGroupItem,
} from './ResetPasswordForm.elements'

// Views
import { Form, FormTextField, Button } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

const ResetPasswordForm = (props) => {
  // Destructure
  const { actions } = props

  // Store Actions
  const { showAlert } = actions

  // Variables
  const initialFormValues = {
    password: '',
    passwordConfirmation: '',
  }

  const validationSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
  })

  // Hooks
  const history = useHistory()
  const [userCredentials] = useLocalStorage('userCredentials')
  const {
    updatePassword,
    updatePasswordData,
    updatePasswordError,
    isUpdatePasswordLoading,
    isUpdatePasswordSuccess,
    isUpdatePasswordError,
  } = useUpdatePassword()

  // Functions
  const handleOnSubmit = (values) => {
    const { password } = values

    const payload = {
      user_id: userCredentials.User_ID,
      new_password: password,
    }

    updatePassword(payload)
  }

  // useEffect
  useEffect(() => {
    if (updatePasswordData && !isUpdatePasswordError) {
      history.push('/')
    }
  }, [updatePasswordData])

  useEffect(() => {
    if (updatePasswordError) {
      showAlert({ type: 'error', message: updatePasswordError.response.data[0].message })
    }
  }, [updatePasswordError])

  return (
    <>
      <ResetPasswordFormWrapper>
        <Form initialValues={initialFormValues} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
          <FormInputGroupWrapper>
            <FormInputGroupItem>
              <ResetPasswordFormLabel>New Password</ResetPasswordFormLabel>
              <FormTextField type="password" name="password" />
            </FormInputGroupItem>
            <FormInputGroupItem>
              <ResetPasswordFormLabel>Confirm Password</ResetPasswordFormLabel>
              <FormTextField type="password" name="passwordConfirmation" />
            </FormInputGroupItem>
          </FormInputGroupWrapper>
          <Button fullWidth type="submit">
            {isUpdatePasswordLoading || isUpdatePasswordSuccess ? 'Submitting...' : 'Submit'}
          </Button>
        </Form>
      </ResetPasswordFormWrapper>
    </>
  )
}

// Default Props
ResetPasswordForm.defaultProps = {
  actions: {},
}

// Proptypes Validation
ResetPasswordForm.propTypes = {
  actions: PropTypes.shape({
    showAlert: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm)
