// Hooks
import { useLocalStorage } from 'core/hooks/storage'

export default () => {
  const [, setUserCredentials] = useLocalStorage('userCredentials', {})
  const [, setIsLoggedIn] = useLocalStorage('isLoggedIn', false)

  const logoutUser = async () => {
    localStorage.clear()
    await setUserCredentials({})
    await setIsLoggedIn(false)
    window.location.reload()
  }

  return { logoutUser }
}
