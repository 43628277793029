import styled from 'styled-components'

export const TableItemWrapper = styled.div`
  position: relative;
  padding: 20px;

  // TODO: Use theme functions
  &:focus {
    outline: none;
  }

  // Themed Properties
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
`

export const TableItemInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const TableItemInner = styled.div`
  flex: 1;
  font-weight: 100;
  font-size: 15px;
`

export const TableItemStartIcon = styled.div`
  display: flex;
  max-height: 30px;
  max-width: 30px;

  transition: all 0.15s ease-in;
`

export const TableItemEndIcon = styled.div`
  display: flex;
  max-height: 30px;
  max-width: 30px;

  transition: all 0.15s ease-in;
`
