/* eslint-disable no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import {
  CaseStatusSummaryWrapper,
  CaseStatusSummaryTextWrapper,
  CaseStatusSummaryText,
} from './CaseStatusSummary.elements'

const CaseStatusSummary = (props) => {
  // Destructure
  const { customerId, total, status, type, caseType } = props

  return (
    <CaseStatusSummaryWrapper>
      <CaseStatusSummaryTextWrapper>
        <CaseStatusSummaryText>
          ID:&nbsp;
          <strong>{customerId}</strong>
        </CaseStatusSummaryText>
        <CaseStatusSummaryText>
          Total&nbsp;
          {`${type === 'address' ? 'Addresses' : type === 'alerts' ? 'Alerts' : 'Transactions'}:`}
          &nbsp;
          <strong>{total}</strong>
        </CaseStatusSummaryText>
      </CaseStatusSummaryTextWrapper>
      <CaseStatusSummaryTextWrapper>
        <CaseStatusSummaryText>
          Status:&nbsp;
          <strong>{status}</strong>
        </CaseStatusSummaryText>
        <CaseStatusSummaryText>
          Type:&nbsp;
          <strong>{caseType === 'Legal Person' ? 'Entity' : 'Individual'}</strong>
        </CaseStatusSummaryText>
      </CaseStatusSummaryTextWrapper>
    </CaseStatusSummaryWrapper>
  )
}

// Default Props
CaseStatusSummary.defaultProps = {
  customerId: '',
  total: 0,
  status: '',
  type: '',
  caseType: '',
}

// Proptypes Validation
CaseStatusSummary.propTypes = {
  customerId: PropTypes.string,
  total: PropTypes.number,
  status: PropTypes.string,
  type: PropTypes.string,
  caseType: PropTypes.string,
}

export default CaseStatusSummary
