import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import CloseIcon from 'assets/images/x.svg'

// Store
import { actions } from 'core/store'

// Styled Elements
import { ModalFooterWrapper, ModalBodyWrapper, ModalFooterButton } from './CasesManagementFilterModal.elements'

// Views
import { Modal, ModalHeader, ModalBody, ModalFooter, Image, Button } from 'views/components'

import { CaseManagementFilterForm } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

const CasesManagementFilterModal = (props) => {
  // Destructure
  const { ui, actions } = props

  // Store State
  const { isCasesManagementFilterModalOpen } = ui

  // Store Actions
  const { toggleCasesManagementFilterModal } = actions

  // useRef
  const formRef = useRef()

  // Functions
  const toggleModal = () => {
    toggleCasesManagementFilterModal()
  }

  const handleClickApplyFilter = () => {
    toggleCasesManagementFilterModal()
    if (formRef.current) {
      formRef.current.submitForm()
    }
  }

  const handleOnTableFilterChange = (params) => {}

  return (
    <Modal isOpen={isCasesManagementFilterModalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal} close={<Image width={20} height={20} src={CloseIcon} onClick={toggleModal} />}>
        Case Management Filters
      </ModalHeader>
      <ModalBody>
        <ModalBodyWrapper>
          <CaseManagementFilterForm formRef={formRef} onChange={handleOnTableFilterChange} />
        </ModalBodyWrapper>
      </ModalBody>
      <ModalFooter>
        <ModalFooterWrapper>
          <ModalFooterButton>
            <Button color="primary" onClick={handleClickApplyFilter}>
              Apply Filters
            </Button>
          </ModalFooterButton>
        </ModalFooterWrapper>
      </ModalFooter>
    </Modal>
  )
}

// Default Props
CasesManagementFilterModal.defaultProps = {
  ui: {},
  actions: {},
}

// Proptypes Validation
CasesManagementFilterModal.propTypes = {
  ui: PropTypes.shape({ isCasesManagementFilterModalOpen: PropTypes.bool }),
  actions: PropTypes.shape({
    toggleCasesManagementFilterModal: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(CasesManagementFilterModal)
