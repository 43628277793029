import React from 'react'

// Library Components
import { Layout } from 'antd'

// Views
import { App, Header, Sidebar, InvestigationChart } from 'views/layouts'

const Investigation = () => (
  <App>
    <Sidebar />
    <Layout>
      <Header />
      <InvestigationChart />
    </Layout>
  </App>
)

// Default Props
Investigation.defaultProps = {}

// Proptypes Validation
Investigation.propTypes = {}

export default Investigation
