import styled from 'styled-components'

export const ProfilePersonalInformationFormWrapper = styled.div``

export const FormTitle = styled.div`
  margin-bottom: 11px;
  font-size: 17px;
  font-weight: 500;

  // Themed Properties
  color: ${({ theme }) => theme.colors.darkGray};
`

export const FormInputGroupWrapper = styled.div``

export const FormInputGroupItem = styled.div`
  margin: 0px 0px 20px 0px;
`
