/* eslint-disable max-len */
/* eslint-disable react/jsx-indent */
import React from 'react'
import moment from 'moment'

// Styled Elements
import { UniqueIDLink, AddressBalanceWrapper } from './AddressesTableItem.elements'

// Views
import { NumericText, LegendText } from 'views/components'

// Utils
import { numberWithCommas } from 'common/utils/valueFormat'
import riskScoreColor from 'common/utils/riskScoreColor'

// Constants
import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'

const formatAccordionDetails = (
	{ caseDetailsData },
	{
    asset,
    balance,
    balanceUsd,
    riskScore,
    flags,
    incoming,
    incomingUsd,
    outgoing,
    outgoingUsd,
    createdBy,
		notes,
		token
	},
	setAccordionDetails
	) => {
	const data = {
		itemDetails: {
			headers: ['Latest Activity:', caseDetailsData?.Last_Modified || 'Not Available'],
			data: [
				{
					name: 'Address Risk Score:',
					data: [<LegendText color={riskScoreColor(riskScore)}>{riskScore}</LegendText>],
				},
				{
					name: 'Flags:',
					data: [flags],
				},
				{
					name: 'Virtual Asset Name:',
					data: [asset],
				},
				{
					name: 'Address Balance:',
					data: [
						<AddressBalanceWrapper>
							<NumericText currency={asset}>{numberWithCommas(balance)}</NumericText>&nbsp;/&nbsp;
							<NumericText currency="USD">{numberWithCommas(balanceUsd)}</NumericText>
						</AddressBalanceWrapper>,
					],
				},
				{
					name: 'Watched Address',
					data: ['No'],
				},
				{
					name: 'Created by:',
					data: [createdBy],
				},
			],
		},
		totalDetails: {
			headers: ['', 'Incoming', 'Outgoing'],
			data: [
				{
					name: 'Total (Virtual Asset):',
					data: [
						<NumericText currency={asset}>{numberWithCommas(incoming)}</NumericText>,
						<NumericText currency={asset}>{numberWithCommas(outgoing)}</NumericText>,
					],
				},
				{
					name: 'Total (USD):',
					data: [
						<NumericText currency="USD">{numberWithCommas(incomingUsd)}</NumericText>,
						<NumericText currency="USD">{numberWithCommas(outgoingUsd)}</NumericText>,
					],
				},
			],
		},
		accountDetails: caseDetailsData && {
			headers: ['Last Modified', '2021-01-07 07:00:39'],
			data: [
				{
					name: 'Account Risk:',
					data: [
						<LegendText color={riskScoreColor(caseDetailsData.Case_Risk)}>{caseDetailsData.Case_Risk}</LegendText>,
					],
				},
				{
					name: 'Unique ID:',
					data: [
						<UniqueIDLink to={`/case-management/details/${caseDetailsData.Customer_ID}`}>
							{caseDetailsData.Customer_ID}
						</UniqueIDLink>,
					],
				},
				{
					name: caseDetailsData.Case_Type === 'Natural Person' ? 'First Name' : 'Company Name',
					data: [
						caseDetailsData.Case_Type === 'Natural Person'
							? caseDetailsData.First_Name
							: caseDetailsData.Company_Name,
					],
				},
				{
					name: caseDetailsData.Case_Type === 'Natural Person' ? 'Last Name' : 'Country of Incorporation',
					data: [
						caseDetailsData.Case_Type === 'Natural Person'
							? caseDetailsData.Last_Name
							: caseDetailsData.Country_Incorporation_Description,
					],
				},
				{
					name: caseDetailsData.Case_Type === 'Natural Person' ? 'Primary Nationality' : 'Country of Domicile',
					data: [
						caseDetailsData.Case_Type === 'Natural Person'
							? caseDetailsData.Nationality_Description
							: caseDetailsData.Country_Address_Description,
					],
				},
				{
					name: 'Linked Addresses',
					data: [caseDetailsData.AddressCount],
				},
				{
					name: 'Linked Transactions',
					data: [caseDetailsData.TxCount],
				},
				{
					name: 'Account Type',
					data: [
						caseDetailsData.Case_Type === 'Natural Person'
							? 'Natural Person' || 'Individual'
							: 'Legal Person' || 'Entity',
					],
				},
				{
					name: 'Account Status',
					data: [caseDetailsData.Case_Status],
				},
				{
					name: 'Account Since',
					data: [moment(caseDetailsData.Case_Since).format(DASHED_DATE_FORMAT)],
				},
			],
		},
		token: token && JSON.parse(token),
		notes: notes || ''
	}

	setAccordionDetails(data)
	return data
}

const formatTokenData = (tokens) => {
	let tokenData = ''
	try {
		const parsedToken = JSON.parse(tokens)
		if (parsedToken) {
			parsedToken.map(({ token_name, balance_approximate, token_address }) => {
				if (!!token_name && !!balance_approximate && !!token_address) {
					tokenData = `${tokenData}${token_name},${balance_approximate},${token_address};`
				}
			})
		}
	} catch {
		console.log('Error formatting token')
	}
	return tokenData
}

export { formatAccordionDetails, formatTokenData }
