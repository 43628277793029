import { authAxios } from 'common/utils/axios'

// Endpoint
import { PROFILE_ENDPOINTS } from 'common/constants/apiUrls'

export const GetUserProfile = async ({ user_id }) => {
  const response = await authAxios.get(PROFILE_ENDPOINTS.READ_PROFILE, {
    params: {
      user_id,
    },
  })

  const data = response.data.content[0]

  return data
}

export const UpdateProfile = async ({ user_id, fullname, business_name, user_role, user_timezone, user_email }) => {
  const response = await authAxios.put(PROFILE_ENDPOINTS.UPDATE_PROFILE, {
    user_id,
    fullname,
    business_name,
    user_role,
    user_timezone,
    user_email,
  })

  const data = response.data.content[0]

  return data
}
