import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

// Constants
import { DASHED_DATE_FORMAT } from 'common/constants/dateFormat'

const initialState = {
  whitelistingFilters: {
    start: moment().subtract(30, 'days').format(DASHED_DATE_FORMAT),
    end: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
    sortBy: '',
    sortDirection: 'DESC',
    pageIndex: 0,
    pageSize: 10,
  },
  monitoringFilters: {
    start: moment().subtract(30, 'days').format(DASHED_DATE_FORMAT),
    end: moment().add(1, 'days').format(DASHED_DATE_FORMAT),
    sortBy: '',
    sortDirection: 'DESC',
    pageIndex: 0,
    pageSize: 10,
  },
  caseManagementFilters: {
    sortBy: 'Case_Since',
    sortDirection: 'DESC',
    pageIndex: 0,
    pageSize: 10,
    caseType: undefined,
    caseStatus: undefined,
  }
}

const store = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setWhitelistingFilters: (state, { payload }) => {
      if (payload) {
        state.whitelistingFilters = {
          ...state.whitelistingFilters,
          ...payload,
        }
      } else {
        state.whitelistingFilters = initialState.whitelistingFilters
      }
    },
    setMonitoringFilters: (state, { payload }) => {
      if (payload) {
        state.monitoringFilters = {
          ...state.monitoringFilters,
          ...payload,
        }
      } else {
        state.monitoringFilters = initialState.monitoringFilters
      }
    },
    setCaseManagementFilters: (state, { payload }) => {
      if (payload) {
        state.caseManagementFilters = {
          ...state.caseManagementFilters,
          ...payload,
        }
      } else {
        state.caseManagementFilters = initialState.caseManagementFilters
      }
    }
  },
})

export default store
