import styled from 'styled-components'

import { Layout } from 'antd'

const { Sider } = Layout

export const SidebarWrapper = styled(Sider).attrs(({ theme }) => ({
  display: theme.layout.sidebar || theme.layout.sidebar === undefined ? 'block' : 'none',
}))`
  display: ${({ display }) => display};

  width: 100%;
  height: 100vh;
`
