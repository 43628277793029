import styled from 'styled-components'

export const ModalFooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ModalBodyWrapper = styled.div`
  margin: 20px 0px 30px 0px;
`

export const ModalFooterButton = styled.div`
  margin: 0px 0px 0px 10px;
`

export const UploadOptions = styled.div`
  display: flex;
  justify-content: space-between;
`

export const UploadOptionItem = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  flex: 1;
  overflow: hidden;
  border: 1px solid ${({ theme }) => (theme.colors.lightGrey)};
  border-radius: 5px;
  padding: 20px;

  cursor: pointer;

  // TODO: Use theme function
  &:last-of-type {
    margin: 10px 0px 10px 10px;
  }

  &:first-of-type {
    margin: 10px 10px 10px 0px;
  }
`
