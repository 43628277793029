/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import {
  BreakdownWrapper,
  BreakdownContainer,
  BreakdownHeaders,
  BreakdownHeaderTitle,
  BreakdownSpan,
  BreakdownRow,
  BreakdownDetails,
} from './DataBreakdownTableScroll.elements'

// Utils
import { numberWithCommas } from 'common/utils/valueFormat'

const DataBreakdownTableScroll = (props) => {
  // Destructure
  const { headers, data } = props

  // Variable
  const withHeader = headers.length
  const withData = data.length

  return (
    <BreakdownWrapper>
      <BreakdownHeaders>
        {withHeader &&
          headers.map((name, i) => (
            <BreakdownHeaderTitle key={i}>
              {name}
            </BreakdownHeaderTitle>
          ))
        }
      </BreakdownHeaders>
      <BreakdownContainer>
        {withData &&
          data.map((item, i) => (
            <BreakdownRow key={i}>
              <BreakdownDetails><BreakdownSpan>{item.token_symbol}</BreakdownSpan>{item.token_name}</BreakdownDetails>
              <BreakdownDetails>${numberWithCommas(item.balance_usd)}</BreakdownDetails>
              <BreakdownDetails>{numberWithCommas(item.balance_approximate)}</BreakdownDetails>
              <BreakdownDetails>{item.token_address}</BreakdownDetails>
            </BreakdownRow>
          ))
        }
      </BreakdownContainer>
    </BreakdownWrapper>
  )
}

// Default Props
DataBreakdownTableScroll.defaultProps = {
  headers: [],
  data: [],
}

// Proptypes Validation
DataBreakdownTableScroll.propTypes = {
  headers: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Array),
}

export default DataBreakdownTableScroll
