import styled from 'styled-components'

export const AutoCompleteWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const Label = styled.label`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkGray};
`

export const AutoCompleteInput = styled.input`
  transition: .4s;

  position: relative;

  min-height: 48px;
  height: fit-content;
  width: 100%;

  padding: 0.7rem 0.75rem;

  font-size: 16px;
  border-radius: 5px;
  font-weight: 400;
  box-shadow: none;
  outline: none;
  color: ${({ theme }) => theme.colors.darkGray};

  border: ${({ theme }) => `1px solid ${theme.colors.lightGrey}`};

  &:hover{
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.1);
  }
`

export const SelectInputOptionsContainer = styled.div`
  position: absolute;
  top: 110%;
  display: flex;
  flex-direction: column;

  padding: 10px;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;

  font-size: 16px;
  border-radius: 5px;
  font-weight: 400;
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.lightGrey}`};
  user-select: none;
  z-index: 2;
`

export const SelectInputOptions = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  gap: 10px;
  color: ${({ theme }) => theme.colors.darkGray};

  cursor: pointer;
  transition: .4s;

  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0);

  &:hover {
    border: ${({ theme }) => `1px solid ${theme.colors.lightGrey}`};
  }
`

export const DummyInput = styled.input`
  display: none;
`
