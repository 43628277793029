import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { DrawerWrapper } from './Drawer.elements'

const Drawer = (props) => {
  // Destructure
  const { anchor, open, onClose, children, maxWidth } = props

  return (
    <DrawerWrapper anchor={anchor} open={open} transitionDuration={200} onClose={onClose} maxwidth={maxWidth}>
      {children}
    </DrawerWrapper>
  )
}

// Default Props
Drawer.defaultProps = {
  anchor: '',
  open: false,
  onClose: () => {},
  children: '',
  maxWidth: '',
}

// Proptypes Validation
Drawer.propTypes = {
  anchor: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Array)]),
}

export default Drawer
