import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { LoaderCircleWrapper, Loader } from './LoaderCircle.elements'

const LoaderCircle = (props) => {
  // Destructure
  const { height, width, thickness } = props

  return (
    <LoaderCircleWrapper height={height} width={width} thickness={thickness}>
      <Loader>
        <div /><div /><div /><div />
      </Loader>
    </LoaderCircleWrapper>
  )
}

// Default Props
LoaderCircle.defaultProps = {
  height: '',
  width: '',
  thickness: ''
}

// Proptypes Validation
LoaderCircle.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  thickness: PropTypes.string,
}

export default LoaderCircle
