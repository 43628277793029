import styled from 'styled-components'

export const SearchbarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 10px;
`

export const SelectWrapper = styled.div`
  max-width: 250px;
  width: 100%;
`

export const SearchInputField = styled.input`
  padding: 0.7rem 0.75rem;
  height: auto;
  width: 100%;
  min-height: 40px;
  box-sizing: border-box;

  border-radius: 5px;
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};

  &:focus {
    box-shadow: none;
    outline: none;
  }

  ${({ theme, error }) => error && `border-color: ${theme.colors.red};`}

  &:focus {
    ${({ theme, error }) => error && `border-color: ${theme.colors.red};`}
  }
`
