import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import CloseIcon from 'assets/images/x.svg'

// Store
import { actions } from 'core/store'

// Styled Elements
import {
  CreateEntityCaseDrawerWrapper,
  DrawerHeader,
  DrawerContent,
  DrawerFooter,
  DrawerCloseIcon,
  DrawerTitle,
} from './CreateEntityCaseDrawer.elements'

// Views
import { Drawer, Image, Button } from 'views/components'

import { CreateEntityCaseForm } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

const CreateEntityCaseDrawer = (props) => {
  // Destructure
  const { ui, actions } = props

  // Store State
  const { isCreateEntityCaseDrawerOpen } = ui

  // Store Actions
  const { closeCreateEntityCaseDrawer } = actions

  // Refs
  const formRef = useRef()

  // Functions
  const handleButtonClickSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm()
    }
  }

  const handleCloseDrawer = () => {
    closeCreateEntityCaseDrawer()
  }

  return (
    <Drawer open={isCreateEntityCaseDrawerOpen} anchor="right" onClose={handleCloseDrawer}>
      <CreateEntityCaseDrawerWrapper>
        <DrawerHeader>
          <DrawerCloseIcon onClick={handleCloseDrawer}>
            <Image width={20} height={20} src={CloseIcon} />
          </DrawerCloseIcon>
          <DrawerTitle>Create Entity Case</DrawerTitle>
        </DrawerHeader>
        <DrawerContent>
          <CreateEntityCaseForm formRef={formRef} />
        </DrawerContent>
        <DrawerFooter>
          <Button onClick={handleButtonClickSubmit}>Create Case</Button>
        </DrawerFooter>
      </CreateEntityCaseDrawerWrapper>
    </Drawer>
  )
}

// Default Props
CreateEntityCaseDrawer.defaultProps = {
  ui: {},
  actions: {},
}

// Proptypes Validation
CreateEntityCaseDrawer.propTypes = {
  ui: PropTypes.shape({ isCreateEntityCaseDrawerOpen: PropTypes.bool }),
  actions: PropTypes.shape({ closeCreateEntityCaseDrawer: PropTypes.func }),
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateEntityCaseDrawer)
