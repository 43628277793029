import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Library Components
import { Layout } from 'antd'

// Assets
import KeyIcon from 'assets/images/icon-3.svg'
import WaveIcon from 'assets/images/icon-1.svg'

// Store
import { actions } from 'core/store'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'

import {
  useFetchMonitoringPagedTable,
  useFetchMonitoringRiskBucket,
  useFetchMonitoringTransactionHistory,
} from 'core/hooks/api'

// Styled Elements
import {
  MonitoringWrapper,
  MonitoringTableWrapper,
  MonitoringChartWrapper,
  RiskScoreChartWrapper,
  ActivityChartWrapper,
} from './Monitoring.elements'

// Views
import { Container, Image } from 'views/components'

import { App, Header, Sidebar, Content, SearchBar, RiskScoreChart, ActivityChart, TransactionsTable } from 'views/layouts'

// Map Redux Props
const mapStateToProps = ({ filters, ui }) => ({ filters, ui })
const mapDispatchToProps = actions

const Monitoring = (props) => {
  // Destructure
  const { actions, filters, ui } = props
  const { monitoringFilters } = filters
  const { isPageTableUpdated } = ui

  // Store Actions
  const { setShowHeaderLoader, setIsPageTableUpdated, showAlert } = actions

  // Hooks
  const [monitoringData, setMonitoringData] = useState({})
  const [monitoringFiltersState, setMonitoringFiltersState] = useState({})
  const [userCredentials] = useLocalStorage('userCredentials', false)
  const {
    monitoringPagedTableData,
    getMonitoringPagedTable,
    isMonitoringPagedTableLoading,
    isMonitoringPagedTableError
  } = useFetchMonitoringPagedTable()
  const {
    monitoringRiskBucketData,
    getMonitoringRiskBucket,
    isMonitoringRiskBucketLoading,
    isMonitoringRiskBucketError
  } = useFetchMonitoringRiskBucket()
  const {
    monitoringTransactionHistoryData,
    getMonitoringTransactionHistory,
    isMonitoringTransactionHistoryLoading,
    isMonitoringTransactionHistoryError
  } = useFetchMonitoringTransactionHistory()

  // Variables
  const isLoading = (
    isMonitoringPagedTableLoading || isMonitoringRiskBucketLoading || isMonitoringTransactionHistoryLoading
  )
  const notLoading = (
    !isMonitoringPagedTableLoading && !isMonitoringRiskBucketLoading && !isMonitoringTransactionHistoryLoading
  )
  const isError = (isMonitoringPagedTableError || isMonitoringRiskBucketError || isMonitoringTransactionHistoryError)

  // Functions
  const fetchData = (params) => {
    const { end, pageIndex, pageSize, sortBy, sortDirection, start } = params

    // only fetching the chart data when dates change
    if (!!end && !!start) {
      getMonitoringRiskBucket({
        biz_id: userCredentials.Business_ID,
        start_range: start,
        end_range: end,
      })
      getMonitoringTransactionHistory({
        biz_id: userCredentials.Business_ID,
        start_range: start,
        end_range: end,
      })
    }

    getMonitoringPagedTable({
      biz_id: userCredentials.Business_ID,
      end_range: monitoringFilters.end,
      start_range: monitoringFilters.start,
      sortby: sortBy,
      sortdir: sortDirection,
      pageindex: pageIndex,
      pagesize: pageSize,
    })
  }

  // useEffects
  useEffect(() => {
    const { end, pageIndex, pageSize, sortBy, sortDirection, start } = monitoringFilters
    if (start === monitoringFiltersState?.start && end === monitoringFiltersState?.end) {
      fetchData({ pageIndex, pageSize, sortBy, sortDirection })
      return setMonitoringFiltersState(monitoringFilters)
    }
    fetchData(monitoringFilters)
    return setMonitoringFiltersState(monitoringFilters)
  }, [monitoringFilters])

  useEffect(() => {
    if (isLoading) { return setShowHeaderLoader(true) }
    if (notLoading) {
      if (monitoringPagedTableData) { setMonitoringData(monitoringPagedTableData) }
      setShowHeaderLoader(false)
    }
  }, [isMonitoringPagedTableLoading, isMonitoringRiskBucketLoading, isMonitoringTransactionHistoryLoading])

  // checking for updates on table data
  useEffect(() => {
    if (isPageTableUpdated) { setIsPageTableUpdated(false); fetchData(monitoringFilters) }
  }, [isPageTableUpdated])

  useEffect(() => {
    if (isError) showAlert({ type: 'error', message: 'An error occured in fetching the data.' })
  }, [isMonitoringPagedTableError, isMonitoringRiskBucketError, isMonitoringTransactionHistoryError])

  return (
    <App>
      <Sidebar />
      <Layout>
        <Header />
        <Content>
          <MonitoringWrapper>
            <SearchBar user_id={userCredentials.User_ID} type="transaction" />
            <Container>
              <MonitoringChartWrapper>
                <RiskScoreChartWrapper>
                  <RiskScoreChart
                    titleIcon={<Image src={KeyIcon} />}
                    data={monitoringRiskBucketData.chart}
                    summaryValue={monitoringRiskBucketData.totalValue}
                    summaryText="Total"
                    title="Transaction Distribution by Risk Score"
                  />
                </RiskScoreChartWrapper>
                <ActivityChartWrapper>
                  <ActivityChart
                    titleIcon={<Image src={WaveIcon} />}
                    data={monitoringTransactionHistoryData}
                    title="Daily Transaction Monitoring Activity"
                  />
                </ActivityChartWrapper>
              </MonitoringChartWrapper>
            </Container>
            <Container>
              <MonitoringTableWrapper>
                <TransactionsTable
                  data={monitoringData}
                  onChange={fetchData}
                  isLoading={isMonitoringPagedTableLoading}
                />
              </MonitoringTableWrapper>
            </Container>
          </MonitoringWrapper>
        </Content>
      </Layout>
    </App>
  )
}

// Default Props
Monitoring.defaultProps = {
  actions: {},
  filters: {},
  ui: {},
}

// Proptypes Validation
Monitoring.propTypes = {
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  filters: PropTypes.shape({
    monitoringFilters: PropTypes.instanceOf(Object)
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(Monitoring)
