import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { ModalBodyWrapper } from './ModalBody.elements'

const ModalBody = (props) => {
  // Destructure
  const { children, ...rest } = props

  return <ModalBodyWrapper {...rest}>{children}</ModalBodyWrapper>
}

// Default Props
ModalBody.defaultProps = {
  children: '',
}

// Proptypes Validation
ModalBody.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
}

export default ModalBody
