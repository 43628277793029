import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { DropdownToggleWrapper } from './DropdownToggle.elements'

const DropdownToggle = (props) => {
  // Destructure
  const { children, caret, color } = props

  return (
    <DropdownToggleWrapper caret={caret} color={color} tag="div">
      {children}
    </DropdownToggleWrapper>
  )
}

// Default Props
DropdownToggle.defaultProps = {
  caret: false,
  color: '',
  children: '',
}

// Proptypes Validation
DropdownToggle.propTypes = {
  caret: PropTypes.bool,
  color: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
}

export default DropdownToggle
