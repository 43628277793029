import styled from 'styled-components'

export const CreateIndividualCaseFormWrapper = styled.div``

export const FormInputGroupItem = styled.div`
  margin-bottom: 15px;
`

export const FormTitle = styled.div`
  margin-bottom: 11px;
  font-size: 17px;
  font-weight: 500;

  color: ${({ theme }) => theme.colors.darkGray};
`
