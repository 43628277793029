import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash.debounce'
import { connect } from 'react-redux'

// Constants
import { CASE_STATUS_OPTIONS, CASE_TYPE_OPTIONS, SORT_BY_OPTIONS } from 'common/constants/formOptions'

// Store
import { actions } from 'core/store'

// Hooks
import { useFetchAutoCompleteCustomer } from 'core/hooks/api'

// Styled Elements
import {
  CaseManagementTableFilterFormWrapper,
  CaseManagementTableFilterInner,
  CaseManagementTableFilterItem,
} from './CaseManagementTableFilterForm.elements'

// Views
import { Select, AutoComplete } from 'views/components'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = (dispatch) => actions(dispatch)

const CaseManagementTableFilterForm = (props) => {
  // Destructure
  const { onCustomerSearch, filters, actions } = props
  const { caseManagementFilters } = filters
  const { caseType, caseStatus, sortBy } = caseManagementFilters

  // Store Actions
  const { setShowHeaderLoader, setCaseManagementFilters } = actions

  // States
  const [customerSearchOptions, setCustomerSearchOptions] = useState([])

  // Hooks
  const {
    autoCompleteCustomerData,
    getAutoCompleteCustomer,
    isAutoCompleteCustomerLoading,
  } = useFetchAutoCompleteCustomer()

  // Functions
  const handleOnAutoCompleteSelect = (value) => {
    onCustomerSearch(value)
  }

  const handleOnCaseTypeSelect = async (value) => {
    if (caseType !== value) {
      await setCaseManagementFilters(
        { ...caseManagementFilters, caseType: Number.isNaN(+value) ? undefined : +value }
      )
    }
  }

  const handleOnCaseStatusSelect = async (value) => {
    if (caseStatus !== value) {
      await setCaseManagementFilters(
        { ...caseManagementFilters, caseStatus: Number.isNaN(+value) ? undefined : +value }
      )
    }
  }

  const handleOnSortBySelect = async (value) => {
    if (sortBy !== value) {
      await setCaseManagementFilters(
        { ...caseManagementFilters, sortBy: value }
      )
    }
  }

  const handleOnAutoCompleteInputChange = debounce((value) => {
    if (!value) { return onCustomerSearch() }
    if (value) { return getAutoCompleteCustomer({ customer_id: value }) }
  }, 500)

  // useEffect
  useEffect(() => {
    if (autoCompleteCustomerData) {
      setCustomerSearchOptions(() =>
        autoCompleteCustomerData.map(({ Customer_ID }) => ({ label: Customer_ID, value: Customer_ID }))
      )
    }
    if (!autoCompleteCustomerData) {
      setCustomerSearchOptions([])
    }
  }, [autoCompleteCustomerData])

  useEffect(() => {
    if (isAutoCompleteCustomerLoading) {
      setShowHeaderLoader(true)
    } else if (!isAutoCompleteCustomerLoading) {
      setShowHeaderLoader(false)
    }
  }, [isAutoCompleteCustomerLoading])

  return (
    <CaseManagementTableFilterFormWrapper>
      <CaseManagementTableFilterInner>
        <CaseManagementTableFilterItem>
          <AutoComplete
            label="Search case"
            placeholder="Search Customer ID"
            onSelect={handleOnAutoCompleteSelect}
            onInputChange={handleOnAutoCompleteInputChange}
            options={customerSearchOptions}
            isLoading={isAutoCompleteCustomerLoading}
          />
        </CaseManagementTableFilterItem>
        <CaseManagementTableFilterItem>
          <Select
            label="Select case type"
            placeholder="Case Type"
            name="caseType"
            onChange={handleOnCaseTypeSelect}
            options={CASE_TYPE_OPTIONS}
            value={caseType || null}
          />
        </CaseManagementTableFilterItem>
        <CaseManagementTableFilterItem>
          <Select
            label="Select case status"
            placeholder="Status"
            name="caseStatus"
            onChange={handleOnCaseStatusSelect}
            options={CASE_STATUS_OPTIONS}
            value={caseStatus || null}
          />
        </CaseManagementTableFilterItem>
        <CaseManagementTableFilterItem>
          <Select
            label="Sort by"
            name="sortBy"
            onChange={handleOnSortBySelect}
            options={SORT_BY_OPTIONS}
            value={sortBy || null}
          />
        </CaseManagementTableFilterItem>
      </CaseManagementTableFilterInner>
    </CaseManagementTableFilterFormWrapper>
  )
}

// Default Props
CaseManagementTableFilterForm.defaultProps = {
  onCustomerSearch: () => {},
  filters: {},
  actions: {},
}

// Proptypes Validation
CaseManagementTableFilterForm.propTypes = {
  onCustomerSearch: PropTypes.func,
  filters: PropTypes.shape({
    caseManagementFilters: PropTypes.shape({
      caseType: PropTypes.number,
      caseStatus: PropTypes.number,
      sortBy: PropTypes.string,
    })
  }),
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    setCaseManagementFilters: PropTypes.func
  })
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseManagementTableFilterForm)
