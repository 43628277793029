import styled from 'styled-components'

export const MonitoringWrapper = styled.div`
  margin: 75px 0px 0px 0px;
`

export const MonitoringChartWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0px 0px 0px;
  justify-content: space-between;
`

export const RiskScoreChartWrapper = styled.div`
  margin: 0px 0px 30px 0px;

  // Responsive Properties
  ${({ theme }) => theme.functions.mediaQuery('width', ['100%', '100%', '100%', '100%', '100%', '50%', '50%', '42%'])}
`

export const ActivityChartWrapper = styled.div`
  margin: 0px 0px 30px 0px;

  // Responsive Properties
  ${({ theme }) => theme.functions.mediaQuery('width', ['100%', '100%', '100%', '100%', '100%', '48%', '48%', '56%'])}
`

export const MonitoringTableWrapper = styled.div`
  padding: 0px;
`
