import styled from 'styled-components'

// Library Components
import { FormGroup } from 'reactstrap'

export const DatePickerWrapper = styled.div`
  width: 100%;
`

export const FormGroupWrapper = styled(FormGroup)`
  position: relative;
  min-height: 48px;

  // Overrides
  &.form-group {
    margin: 0px;
  }
`

export const Label = styled.label`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkGray};
`

export const DateInput = styled.input`
  display: none;
`

export const SelectOption = styled.option`
  color: ${({ theme }) => theme.colors.darkGray};
`

export const DateInputLabel = styled.div`
  cursor: pointer;
  transition: .4s;
  color: ${({ theme }) => theme.colors.darkGray};

  position: relative;
  // TODO: Use theme functions
  &:after {
    content: '';

    position: absolute;
    top: 42%;
    right: 15px;

    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;

    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    color: ${({ theme }) => theme.colors.darkGray};
  }

  min-height: 48px;
  height: fit-content;
  width: 100%;

  padding: 0.7rem 0.75rem;

  font-size: 16px;
  border-radius: 5px;
  font-weight: 400;
  box-shadow: none;

  border: ${({ theme }) => `1px solid ${theme.colors.lightGrey}`};

  &:hover{
    box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.1);
  }
`

export const SelectInputOptionsContainer = styled.div`
  position: absolute;
  top: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 300px;
  overflow-y: auto;

  padding: 10px;
  width: 100%;

  font-size: 14px;
  border-radius: 5px;
  font-weight: 400;
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.lightGrey}`};
  user-select: none;
  z-index: 2;
`

export const SelectInputOptions = styled.div`
  display: flex;
  padding: 10px;
  color: ${({ theme }) => theme.colors.darkGray};

  cursor: pointer;
  transition: .4s;

  border: ${({ theme }) => `1px solid ${theme.colors.lightGrey}`};
  border-radius: 5px;

  &:hover {
    background: ${({ theme }) => theme.colors.ash};
  }
`

export const FormErrorMessage = styled.div`
  position: absolute;
  bottom: -20px;
  left: 5px;
  color: ${({ theme }) => theme.colors.brightRed};
  font-size: 14px;
`
