import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'

// Library Components
import { TabContent } from 'reactstrap'

// Styled Elements
import {
  TabsOuterWrapper,
  TabsWrapper,
  TabsHeader,
  TabHeaderIcon,
  TabsHeaderTitle,
  TabsBody,
  TabsTopNav,
  DetachedSideNav,
  TabsSideNav,
  TabsContentWrapper,
  TabsFooter,
  TabsSideNavWrapper,
  TabsSideNavItem,
  TabsTopNavWrapper,
  TabsTopNavItem,
} from './Tabs.elements'

const Tabs = (props) => {
  // Destructure
  const { children, type, titleIcon, title, defaultTabId, tabs } = props

  // States
  const [currentTab, setCurrentTab] = useState(defaultTabId.toString())

  // Functions
  const handleSelectTab = (id) => {
    setCurrentTab(id.toString())
  }

  return (
    <TabsOuterWrapper>
      <DetachedSideNav tabs type={type}>
        {tabs.map(({ id, name }) => (
          <TabsSideNavItem key={uuid()} onClick={() => handleSelectTab(id)} active={id.toString() === currentTab}>
            {name}
          </TabsSideNavItem>
        ))}
      </DetachedSideNav>
      <TabsWrapper type={type}>
        <TabsHeader>
          <TabHeaderIcon>{titleIcon}</TabHeaderIcon>
          <TabsHeaderTitle type={type}>{title}</TabsHeaderTitle>
        </TabsHeader>
        <TabsBody>
          <TabsTopNav>
            <TabsTopNavWrapper>
              {tabs.map(({ id, name }) => (
                <TabsTopNavItem key={uuid()} onClick={() => handleSelectTab(id)} active={id.toString() === currentTab}>
                  {name}
                </TabsTopNavItem>
              ))}
            </TabsTopNavWrapper>
          </TabsTopNav>
          <TabsSideNav type={type}>
            <TabsSideNavWrapper>
              {tabs.map(({ id, name }) => (
                <TabsSideNavItem key={uuid()} onClick={() => handleSelectTab(id)} active={id.toString() === currentTab}>
                  {name}
                </TabsSideNavItem>
              ))}
            </TabsSideNavWrapper>
          </TabsSideNav>
          <TabsContentWrapper type={type}>
            <TabContent activeTab={currentTab}>{children}</TabContent>
          </TabsContentWrapper>
        </TabsBody>
        {tabs.map(
          ({ id, footer }) => id.toString() === currentTab && footer && <TabsFooter key={uuid()}>{footer}</TabsFooter>
        )}
      </TabsWrapper>
    </TabsOuterWrapper>
  )
}

// Default Props
Tabs.defaultProps = {
  title: '',
  titleIcon: '',
  type: 'compact',
  defaultTabId: '1',
  tabs: [],
  children: '',
}

// Proptypes Validation
Tabs.propTypes = {
  title: PropTypes.string,
  titleIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  type: PropTypes.string,
  defaultTabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tabs: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
}

export default Tabs
