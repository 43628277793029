import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { DropdownMenuWrapper } from './DropdownMenu.elements'

const DropdownMenu = (props) => {
  // Destructure
  const { children, left } = props

  return <DropdownMenuWrapper right={left}>{children}</DropdownMenuWrapper>
}

// Default Props
DropdownMenu.defaultProps = {
  left: false,
  children: '',
}

// Proptypes Validation
DropdownMenu.propTypes = {
  left: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
}

export default DropdownMenu
