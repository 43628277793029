import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { LoaderLineWrapper, Loader } from './LoaderLine.elements'

const LoaderLine = (props) => {
  // Destructure
  const { show } = props

  return (
    show && (
      <LoaderLineWrapper>
        <Loader />
      </LoaderLineWrapper>
    )
  )
}

// Default Props
LoaderLine.defaultProps = {
  show: false,
}

// Proptypes Validation
LoaderLine.propTypes = {
  show: PropTypes.bool,
}

export default LoaderLine
