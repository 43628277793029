import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { InputWrapper, Label, TextFieldInput } from './TextField.elements'

const TextField = (props) => {
  // Destructure
  const { id, type, label, ...rest } = props

  return (
    <InputWrapper>
      {label && <Label>{label}</Label>}
      <TextFieldInput id={id} type={type} {...rest} />
    </InputWrapper>
  )
}

// Default Props
TextField.defaultProps = {
  id: '',
  type: '',
  label: '',
}

// Proptypes Validation
TextField.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
}

export default TextField
