import styled from 'styled-components'

// Library Components
import { Accordion } from 'react-accessible-accordion'

export const AccordionTableWrapper = styled(Accordion)`
  width: 100%;
  height: fit-content;
  min-height: 600px;

  overflow: hidden;

  font-size: 18px;

  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  border-radius: 5px;
`

export const AccordionTableHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 20px;
  border-radius: 5px 5px 0 0;

  // Themed Properties
  background: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
`

export const AccordionHeaderTitleWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  width: fit-content;
`

export const AccordionHeaderIcon = styled.div`
  width: 32px;
  height: 32px;
`

export const AccordionHeaderTitle = styled.div`
  margin: 0px 0px 0px 10px;
  color: ${({ theme }) => theme.colors.darkGray};
  font-weight: 500;
`

export const AccordionHeaderFilterButton = styled.div`
  display: flex;
  align-items: center;
  justify-self: flex-end;
`

export const AccordionHeaderOptionsWrapper = styled.div``

export const AccordionFilterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  overflow: hidden;

  height: 0px;
  width: 100%;
  padding: 0px 20px;
  border-bottom: none;

  transition: all 0.1s ease-in;

  // Themed Properties
  background: ${({ theme }) => theme.colors.white};

  // Conditional Properties
  ${({ collapseFilter, withFilter }) => !collapseFilter && withFilter && 'height: auto;'}
  ${({ collapseFilter, withFilter }) => !collapseFilter && withFilter && 'padding: 20px;'}
  ${({ theme, collapseFilter, withFilter }) =>
    !collapseFilter && withFilter && `border-bottom: 1px solid ${theme.colors.lightGrey}`}
`

export const AccordionTableBodyOverflowWrapper = styled.div`
  overflow-x: auto;
`

export const AccordionTableBodyWrapper = styled.div`
  // Conditional Properties
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px;`}
`

export const AccordionTableBodyHeaderWrapper = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;

  padding: 20px 55px 20px 30px;

  // Themed Properties
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};

  // Conditional Properties
  ${({ showHeader }) => showHeader && 'display: flex;'}
`

export const AccordionTableBodyHeaderItem = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;

  text-align: center;
  font-weight: 400;
  font-size: 15px;
  font-family: Roboto;

  // Themed Properties
  color: ${({ theme }) => theme.colors.darkGray};

  // Conditional Properties
  ${({ sortable }) => sortable && 'cursor: pointer;'}
  ${({ width }) => width && `width: ${width}px;`}
  ${({ width }) => width && 'flex: none;'}
`

export const AccordionTableBodyHeaderText = styled.div``

export const AccordionTableBodySubHeaderText = styled.div`
  text-align: left;
  font-size: 12px;
  font-weight: 300;
`

export const AccordionTableBodyHeaderIcon = styled.span`
  display: none;
  margin: 0px 0px 0px 6px;

  transition: all 0.1s ease-in;

  // Conditional Properties
  ${({ active }) => active && 'display: flex;'}
  ${({ sortDirection }) => sortDirection === 'DESC' && 'transform: rotate(180deg);'}
`
export const AccordionTableBodyChildrenWrapper = styled.div`
`
