import React, { useState } from 'react'
import PropTypes from 'prop-types'
import WAValidator from 'multicoin-address-validator'

// Constants
import { CHAIN_TYPE_OPTIONS } from 'common/constants/formOptions'

// Styled Elements
import {
  SearchbarWrapper,
  SelectWrapper,
  SearchInputField,
} from './SearchInput.elements'

// Views
import { Select } from 'views/components'

const SearchInput = (props) => {
  // Destructure
  const { error, handleOnSubmit, type } = props

  // States
  const [hash, setHash] = useState('')
  const [chainOptions, setChainOptions] = useState(CHAIN_TYPE_OPTIONS)
  const [placeholder, setPlaceholder] = useState('Select Chain')
  const [chainType, setChainType] = useState('')

  // Functions
  const checkChain = (value) => {
    if (value) {
      const data = []
      CHAIN_TYPE_OPTIONS.map((item) => {
        if (type === 'address') {
          const valid = WAValidator.validate(value, item.abrv.toLowerCase())
          if (valid) {
            return data.push(item)
          }
        }
        if (type === 'transaction') {
          return data.push(item)
        }
      })
      if (data?.length) {
        setChainOptions(data)
      } else if (!data?.length) {
        setChainOptions(CHAIN_TYPE_OPTIONS)
      }
    } if (!value) {
      setChainOptions(CHAIN_TYPE_OPTIONS)
    }
  }
  const handleInputChange = (event) => {
    const { value } = event.target
    setPlaceholder('Select Chain')
    setHash(value)
    checkChain(value)
  }

  const handleSelectChange = (value) => {
    setChainType(value)
    if (hash && value) {
      handleOnSubmit(hash, value)
      setTimeout(() => {
        setHash('')
        setChainType('')
      }, 500)
    }
  }

  return (
    <SearchbarWrapper>
      <SearchInputField
        placeholder="Enter Hash"
        error={error ? 1 : 0}
        value={hash}
        onChange={handleInputChange}
      />
      <SelectWrapper>
        <Select
          placeholder={placeholder}
          options={chainOptions}
          value={chainType}
          disabled={!hash}
          onChange={handleSelectChange}
        />
      </SelectWrapper>
    </SearchbarWrapper>
  )
}

// Default Props
SearchInput.defaultProps = {
  error: false,
  handleOnSubmit: () => {},
  type: '',
}

// Proptypes Validation
SearchInput.propTypes = {
  error: PropTypes.bool,
  handleOnSubmit: PropTypes.func,
  type: PropTypes.string,
}

export default SearchInput
