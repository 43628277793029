import styled from 'styled-components'

export const ContainerWrapper = styled.div`
  margin: 0 auto;
  padding: 0px 24px;

  // Responsive Properties
  ${({ theme }) =>
    theme.functions.mediaQuery('max-width', ['720px', '720px', '720px', '720px', '720px', '1024px', '1024px'])}
`
