import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { DropdownItemWrapper } from './DropdownItem.elements'

const DropdownItem = (props) => {
  // Destructure
  const { children, ...rest } = props

  return <DropdownItemWrapper {...rest}>{children}</DropdownItemWrapper>
}

// Default Props
DropdownItem.defaultProps = {
  children: '',
}

// Proptypes Validation
DropdownItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
}

export default DropdownItem
