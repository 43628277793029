import styled from 'styled-components'

export const LoginWrapper = styled.div`
  min-height: 100vh;
`

export const LoginInnerWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LoginFormWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`

export const LoginFormInnerWrapper = styled.div`
  // Responsive Properties
  ${({ theme }) => theme.functions.mediaQuery('width', ['98%', '98%', '98%', '420px'])}
`

export const LoginFormContent = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  padding: 40px;
  width: 100%;

  background: ${({ theme }) => theme.colors.white};
  border-radius: 5px;

`

export const LoginLogoLink = styled.a`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`

export const LoginBrandWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  min-height: 100vh;
`

export const LoginBottomWrapper = styled.div`
  align-self: center;

  //TODO: Style Variable
  color: ${({ theme }) => theme.colors.white};
`

export const PrivacyPolicyLink = styled.a`
  display: block;
  margin: 20px 0px 0px 0px;
  width: 100%;

  font-weight: 400;
  font-size: 12px;
  text-decoration: underline;
  text-align: center;

  color: ${({ theme }) => theme.colors.darkGray};
`

export const LoginFormFooterText = styled.p`
  margin: 35px 0px 10px 0px;

  font-size: 16px;
  color: ${({ theme }) => theme.colors.darkGray};
  text-align: center;
  font-weight: 100;
`

export const LoginFormFooterLink = styled.a`
  font-weight: 500;
  color: ${({ theme }) => theme.colors.darkGray};
`
