export const numberWithCommas = (value) => (value ? value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',') : value)

export const abbreviateNumber = (value, decimalPlace = 0) => {
  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for (i = si.length - 1; i > 0; i--) {
    if (Number.isNaN(value) ? 0 : value >= si[i].value) {
      break
    }
  }

  const safeValue = value / si[i].value || 0

  return safeValue.toFixed(decimalPlace).replace(rx, '$1') + si[i].symbol
}

export const formatChain = (value) => value.replace('-', ' ')
