import styled from 'styled-components'

export const NotFoundWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const NotFoundTitle = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 24px;
  font-weight: 600;
`
export const NotFoundText = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkGray};
`
export const NotFoundLink = styled.div`
  display: flex;
  justify-content: center;
`
export const NotFoundBox = styled.div`
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  border-radius: 5px;
`
