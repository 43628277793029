import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useField, ErrorMessage } from 'formik'

// Styled Elements
import {
  Label,
  DateInput,
  FormErrorMessage,
  FormGroupWrapper,
  DatePickerWrapper,
} from './FormDatePicker.elements'

// Views
import { DatePicker } from 'views/components'

const FormDatePicker = (props) => {
  // Destructure
  const { label, placeholder, options, ...rest } = props

  const [fields] = useField(props)

  // Variables
  const wrapperRef = useRef()
  const inputRef = useRef(null)
  const { name, value, onChange } = fields

  // Hooks
  const [formValue, setFormValue] = useState('')

  // Functions
  const handleOnChange = async (elValue) => {
    await setFormValue(elValue)
    // manually calling onchange event of formik input through onClick
    inputRef.current.click()
  }

  // UseEffects
  useEffect(() => {
    // setting the value on initial load
    if (value) {
      setFormValue(value)
    }
  }, [value])

  return (
    <DatePickerWrapper ref={wrapperRef}>
      {label && <Label>{label}</Label>}
      <FormGroupWrapper>
        <DateInput
          ref={inputRef}
          onClick={(e) => { onChange(e) }}
          name={name}
          onChange={() => {}}
          value={formValue}
          type="date"
          {...rest}
        />
        <DatePicker defaultDate={formValue} onApply={handleOnChange} />
        <FormErrorMessage>
          <ErrorMessage {...fields} />
        </FormErrorMessage>
      </FormGroupWrapper>
    </DatePickerWrapper>
  )
}

// Default Props
FormDatePicker.defaultProps = {
  label: '',
  placeholder: '',
  options: [],
}

// Proptypes Validation
FormDatePicker.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.instanceOf(Array),
}

export default FormDatePicker
