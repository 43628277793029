import React from 'react'

// Constants
import { EXTERNAL_URLS } from 'common/constants/apiUrls'

// Assets
import Logo from 'assets/images/brand-logo-blue.png'

// Styled Elements
import {
  LoginWrapper,
  LoginInnerWrapper,
  LoginLogoLink,
  LoginFormWrapper,
  LoginFormInnerWrapper,
  LoginFormContent,
  LoginFormFooterText,
  LoginFormFooterLink,
  PrivacyPolicyLink,
} from './Login.elements'

// Components
import { Image } from 'views/components'
import { LoginForm } from 'views/layouts'

const Login = () => (
  <LoginWrapper>
    <LoginInnerWrapper>
      <LoginFormWrapper>
        <LoginFormInnerWrapper>
          <LoginFormContent>
            <LoginLogoLink>
              <Image src={Logo} width={150} />
            </LoginLogoLink>
            <LoginForm />
            <LoginFormFooterText>
              Don&apos;t have an account?&nbsp;&nbsp;
              <LoginFormFooterLink href={EXTERNAL_URLS.GET_STARTED}>Start a free trial today.</LoginFormFooterLink>
            </LoginFormFooterText>
          </LoginFormContent>
          <PrivacyPolicyLink href={EXTERNAL_URLS.PRIVACY_POLICY}>Privacy Policy • Terms of Use</PrivacyPolicyLink>
        </LoginFormInnerWrapper>
      </LoginFormWrapper>
    </LoginInnerWrapper>
  </LoginWrapper>
)

// Default Props
Login.defaultProps = {}

// Proptypes Validation
Login.propTypes = {}

export default Login
