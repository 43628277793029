import styled from 'styled-components'

export const InputWrapper = styled.div`
  position: relative;
`

export const Label = styled.label`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkGray};
`

export const TextAreaInput = styled.textarea`
  padding: 0.7rem 0.75rem;
  font-size: 16px;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  font-weight: 400;
  outline: none;
  color: ${({ theme }) => theme.colors.darkGray};
  
  border: ${({ theme }) => `1px solid ${theme.colors.lightGrey}`};
  
  ${({ resize }) => !resize && 'resize: none;'}
`

export const SaveLoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 10px;
  right: 10px;
`

export const SaveLoaderTitle = styled.div`
  cursor: default;
  font-size: 10px;
  color: ${({ theme }) => theme.colors.lightGrey};
`
