import styled from 'styled-components'

export const CaseManagementFilterFormWrapper = styled.div`
  width: 100%;
`

export const FormAutoCompleteWrapper = styled.div`
  width: 100%;
  display: block;
`

export const CaseManagementFilterInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px 0px 0px 0px;
  gap: 20px;
`

export const CaseManagementFilterItem = styled.div`
  margin: 5px 0px 0px 0px;
  width: 100%;
`
