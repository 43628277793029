import styled from 'styled-components'

export const AddressesWrapper = styled.div`
  margin: 110px 0px 0px 0px;
`

export const CasesSubHeader = styled.div``

export const CaseStatusSummaryWrapper = styled.div`
  padding: 20px 0px;
`
