import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Store
import { actions } from 'core/store'

// Styled Elements
import { SearchBarWrapper, SearchInputWrapper } from './SearchBar.elements'

// Views
import { Container, SearchInput } from 'views/components'

// Hooks
import { useFetchMonitoringTransactionSearch, useFetchWhitelistingAddressSearch } from 'core/hooks/api'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

const SearchBar = (props) => {
  // props
  const { user_id, type, actions } = props

  // redux actions
  const { setShowHeaderLoader, showAlert, setIsPageTableUpdated } = actions

  // hooks
  const {
    getMonitoringSearchTransaction,
    monitoringSearchTransactionData,
    monitoringSearchTransactionError,
    isMonitoringSearchTransactionLoading
  } = useFetchMonitoringTransactionSearch()
  const {
    getWhitelistingSearchAddress,
    whitelistingSearchAddressData,
    whitelistingSearchAddressError,
    isWhitelistingSearchAddressLoading
  } = useFetchWhitelistingAddressSearch()

  // Functions
  const handleOnSubmit = (hash, chain) => {
    if (hash && chain) {
      if (type === 'transaction') {
        getMonitoringSearchTransaction({ user_id, chain, transaction: hash })
      } else if (type === 'address') {
        getWhitelistingSearchAddress({ user_id, chain, address: hash })
      }
    }
  }

  // useEffcts
  useEffect(() => {
    if (monitoringSearchTransactionData || monitoringSearchTransactionError) {
      if (!monitoringSearchTransactionError) {
        setIsPageTableUpdated(true)
      } else if (monitoringSearchTransactionError) {
        showAlert({ type: 'error', message: 'Incorrect or unsupported asset. Please review and submit again.' })
      }
    } else if (whitelistingSearchAddressData || whitelistingSearchAddressError) {
      if (!whitelistingSearchAddressError) {
        setIsPageTableUpdated(true)
      } else if (whitelistingSearchAddressError) {
        showAlert({ type: 'error', message: 'Incorrect or unsupported asset. Please review and submit again.' })
      }
    }
  }, [
    monitoringSearchTransactionData,
    monitoringSearchTransactionError,
    whitelistingSearchAddressData,
    whitelistingSearchAddressError,
  ])

  useEffect(() => {
    if (isMonitoringSearchTransactionLoading || isWhitelistingSearchAddressLoading) {
      setShowHeaderLoader(true)
    } else if (!isMonitoringSearchTransactionLoading && !isWhitelistingSearchAddressLoading) {
      setShowHeaderLoader(false)
    }
  }, [isMonitoringSearchTransactionLoading, isWhitelistingSearchAddressLoading])

  return (
    <SearchBarWrapper>
      <Container>
        <SearchInputWrapper>
          <SearchInput handleOnSubmit={handleOnSubmit} type={type} />
        </SearchInputWrapper>
      </Container>
    </SearchBarWrapper>
  )
}

// Default Props
SearchBar.defaultProps = {
  user_id: '',
  type: '',
  actions: {},
}

// Proptypes Validation
SearchBar.propTypes = {
  user_id: PropTypes.string,
  type: PropTypes.string,
  actions: PropTypes.shape({
    setShowHeaderLoader: PropTypes.func,
    showAlert: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar)
