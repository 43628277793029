import styled from 'styled-components'

export const InputWrapper = styled.div`
  width: 100%;
`

export const Label = styled.label`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkGray};
`

export const TextFieldInput = styled.input`
  padding: 0.7rem 0.75rem;
  font-size: 16px;
  border-radius: 5px;
  width: 100%;
  font-weight: 400;
  outline: none;
  color: ${({ theme }) => theme.colors.darkGray};
  -webkit-font-smoothing: antialiased;

  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.lightGrey}`};

  ${({ disabled, theme }) => disabled && `background-color: ${theme.colors.aliceBlue}`}
`
