import styled from 'styled-components'

// Library Components
import { FormGroup } from 'reactstrap'

export const SelectWrapper = styled.div`
  width: 100%;
  min-height: 48px;
`

export const FormGroupWrapper = styled(FormGroup)`
  position: relative;

  // Overrides
  &.form-group {
    margin: 0px;
  }
`

export const Label = styled.label`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkGray};
`

export const SelectInput = styled.select`
  display: none;
`

export const SelectOption = styled.option`
  color: ${({ theme }) => theme.colors.darkGray};
`

export const SelectInputLabel = styled.div`
  color: ${({ theme }) => theme.colors.darkGray};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  transition: .4s;

  position: relative;
  // TODO: Use theme functions
  &:after {
    content: '';

    position: absolute;
    top: 42%;
    right: 15px;

    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;

    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    color: ${({ theme }) => theme.colors.darkGray};
  }

  min-height: 48px;
  height: fit-content;
  width: 100%;

  padding: 0.7rem 0.75rem;

  font-size: 16px;
  border-radius: 5px;
  font-weight: 400;
  box-shadow: none;

  border: ${({ theme }) => `1px solid ${theme.colors.lightGrey}`};

  &:hover{
    box-shadow: ${(props) => (props.disabled ? '' : '0px 0px 10px 2px rgba(0,0,0,0.1)')};
  }
`

export const SelectInputOptionsContainer = styled.div`
  position: absolute;
  top: 110%;
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.darkGray};

  padding: 10px;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;

  font-size: 16px;
  border-radius: 5px;
  font-weight: 400;
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => `1px solid ${theme.colors.lightGrey}`};
  user-select: none;
  z-index: 2;
`

export const SelectInputOptions = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  gap: 10px;

  color: ${({ theme }) => theme.colors.darkGray};

  cursor: pointer;
  transition: .4s;

  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0);

  &:hover {
    border: ${({ theme }) => `1px solid ${theme.colors.lightGrey}`};
  }
`

export const SelectPlaceholder = styled.div`
  color: ${(props) => (props.disabled ? props.theme.colors.lightGrey : props.theme.colors.darkGray)};
  font-weight: 400;
`

export const Abbreviation = styled.div`
  color: ${({ theme }) => theme.colors.lightGrey};
`
