import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'

// Styled Elements
import { FormInputWrapper, FormLabel, FormTextAreaInput } from './FormTextArea.elements'

const FormTextArea = (props) => {
  // Destructure
  const { type, label, resize, ...rest } = props

  // Hooks
  const [fields] = useField(props)

  return (
    <FormInputWrapper>
      {label && <FormLabel>{label}</FormLabel>}
      <FormTextAreaInput type={type} resize={resize} {...fields} {...rest} />
    </FormInputWrapper>
  )
}

// Default Props
FormTextArea.defaultProps = {
  type: '',
  label: '',
  resize: true,
}

// Proptypes Validation
FormTextArea.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  resize: PropTypes.bool,
}

export default FormTextArea
