import React from 'react'

import {
  Alerts,
  AppSidebarDrawer,
  AssignToCaseDrawer,
  CreateIndividualCaseDrawer,
  CreateEntityCaseDrawer,
  DeleteAddressModal,
  CasesManagementFilterModal,
  UploadCaseModal,
  ChooseKYCUploadModal,
} from 'views/layouts'

const Global = () => (
  <>
    {/* Alerts */}
    <Alerts />

    {/* Drawers */}
    <AppSidebarDrawer />
    <AssignToCaseDrawer />
    <CreateIndividualCaseDrawer />
    <CreateEntityCaseDrawer />

    {/* Modals */}
    <DeleteAddressModal />
    <CasesManagementFilterModal />
    <UploadCaseModal />
    <ChooseKYCUploadModal />
  </>
)

export default Global
