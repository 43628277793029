import styled from 'styled-components'

import { DropdownItem } from 'reactstrap'

export const DropdownItemWrapper = styled(DropdownItem)`
  display: flex;
  align-items: center;

  padding: 8px 20px;

  font-weight: 300;
  font-size: 16px;

  // TODO: Use theme functions
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  }

  &:hover {
    background-color: unset;
  }

  &:focus {
    background-color: unset;
    color: inherit;
  }
`
