import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import {
  RiskScoreChartWrapper,
  RiskScoreChartContent,
  RiskScoreChartBreakdown,
  RiskScoreChartBreakdownWrapper,
  RiskScoreChartBreakdownItem,
  DoughnutChartWrapper,
  ChartTotal,
  ChartTotalValue,
  ChartTotalText,
} from './RiskScoreChart.elements'

// Views
import { Card, DoughnutChart, LegendText } from 'views/components'

const RiskScoreChart = (props) => {
  // Destructure
  const { title, summaryValue, summaryText, titleIcon, data } = props

  const { datasets } = data

  data.datasets[0].backgroundColor = ['#a1d99b', '#ffc435', '#f67d84']
  return (
    <RiskScoreChartWrapper>
      <Card title={title} titleIcon={titleIcon}>
        <RiskScoreChartContent>
          <DoughnutChartWrapper>
            <ChartTotal>
              <ChartTotalValue>{summaryValue}</ChartTotalValue>
              <ChartTotalText>{summaryText}</ChartTotalText>
            </ChartTotal>
            <DoughnutChart
              data={data}
              width={200}
              height={200}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                  display: false,
                },
              }}
            />
          </DoughnutChartWrapper>
          <RiskScoreChartBreakdownWrapper>
            <RiskScoreChartBreakdown>
              <RiskScoreChartBreakdownItem>Score Ranges</RiskScoreChartBreakdownItem>
              <RiskScoreChartBreakdownItem>
                <LegendText color="#9bdbc1">0-5</LegendText>
              </RiskScoreChartBreakdownItem>
              <RiskScoreChartBreakdownItem>
                <LegendText color="#ffda81">6-7</LegendText>
              </RiskScoreChartBreakdownItem>
              <RiskScoreChartBreakdownItem>
                <LegendText color="#f5a4a3">8-10</LegendText>
              </RiskScoreChartBreakdownItem>
            </RiskScoreChartBreakdown>
            <RiskScoreChartBreakdown>
              <RiskScoreChartBreakdownItem>Results</RiskScoreChartBreakdownItem>
              <RiskScoreChartBreakdownItem>{datasets[0].data[0]}</RiskScoreChartBreakdownItem>
              <RiskScoreChartBreakdownItem>{datasets[0].data[1]}</RiskScoreChartBreakdownItem>
              <RiskScoreChartBreakdownItem>{datasets[0].data[2]}</RiskScoreChartBreakdownItem>
            </RiskScoreChartBreakdown>
          </RiskScoreChartBreakdownWrapper>
        </RiskScoreChartContent>
      </Card>
    </RiskScoreChartWrapper>
  )
}

// Default Props
RiskScoreChart.defaultProps = {
  title: '',
  titleIcon: '',
  summaryValue: '',
  summaryText: '',
  data: { label: [], datasets: [] },
}

// Proptypes Validation
RiskScoreChart.propTypes = {
  title: PropTypes.string,
  titleIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
  summaryValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  summaryText: PropTypes.string,
  data: PropTypes.shape({
    labels: PropTypes.instanceOf(Array),
    datasets: PropTypes.instanceOf(Array),
  }),
}

export default RiskScoreChart
