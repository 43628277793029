import styled from 'styled-components'

export const UserProfileWrapper = styled.div`
  margin: 110px 0px 0px 0px;
`

export const UserProfileTabsWrapper = styled.div``

export const TabHeaderIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px;
  width: 25px;
  height: 25px;
  border: 1.5px solid #606167;
  border-radius: 100%;
`
