import React from 'react'

// Styled Elements
import { LoadingTablePlaceholderWrapper } from './LoadingTablePlaceholder.elements'

// Views
import { LoaderCircle } from 'views/components'

const LoadingTablePlaceholder = () => (
  <LoadingTablePlaceholderWrapper>
    <LoaderCircle />
  </LoadingTablePlaceholderWrapper>
)

// Default Props
LoadingTablePlaceholder.defaultProps = {}

// Proptypes Validation
LoadingTablePlaceholder.propTypes = {}

export default LoadingTablePlaceholder
