// Assets
import Bitcoin from 'assets/images/blockChainLogos/bitcoin.png'
import BitcoinCash from 'assets/images/blockChainLogos/bitcoinCash.png'
import Ethereum from 'assets/images/blockChainLogos/ethereum.png'
import Litecoin from 'assets/images/blockChainLogos/litecoin.png'

export const CASE_TYPE_OPTIONS = [
  { label: 'All' },
  { value: 1, label: 'Natural Person' },
  { value: 2, label: 'Legal Person' },
]

export const CASE_RISK_OPTIONS = [
  { value: 0, label: 'Not Available' },
  { value: 1, label: 'Low' },
  { value: 2, label: 'Medium' },
  { value: 3, label: 'High' },
]

export const ADVERSE_MEDIA_OPTIONS = [
  { value: 0, label: 'Not Available' },
  { value: 1, label: 'No' },
  { value: 2, label: 'Yes' },
]

export const CASE_STATUS_OPTIONS = [
  { label: 'All' },
  { value: 0, label: 'Inactive' },
  { value: 1, label: 'Active' },
  { value: 2, label: 'On Hold' },
  { value: 3, label: 'Dormant' },
]

export const SORT_BY_OPTIONS = [
  { value: 'Case_Since', label: 'Case Since' },
  { value: 'AddressCount', label: 'Address Count' },
  { value: 'TxCount', label: 'Transaction Count' },
]

export const POLITICALLY_EXPOSED_PERSON_OPTIONS = [
  { value: 0, label: 'Not Available' },
  { value: 1, label: 'No' },
  { value: 2, label: 'Yes' },
]

export const CHAIN_TYPE_OPTIONS = [
  {
    value: 'bitcoin',
    label: 'Bitcoin',
    abrv: 'BTC',
    icon: Bitcoin,
    regex: {
      transaction: /^[a-fA-F0-9]{64}$/,
      address: /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$/
    }
  },
  {
    value: 'ethereum',
    label: 'Ethereum',
    abrv: 'ETH',
    icon: Ethereum,
    regex: {
      transaction: /^0x([A-Fa-f0-9]{64})$/,
      address: /^(0x){1}[0-9a-fA-F]{40}/
    }
  },
  {
    value: 'bitcoin-cash',
    label: 'Bitcoin Cash',
    abrv: 'BCH',
    icon: BitcoinCash,
    regex: {
      /* transaction id regex identifies any SHA256 hash */
      transaction: /[0-9a-f]{64}/,
      address: /[13][a-km-zA-HJ-NP-Z1-9]{33}/
    }
  },
  {
    value: 'litecoin',
    label: 'Litecoin',
    abrv: 'LTC',
    icon: Litecoin,
    regex: {
      /* transaction id regex identifies any Scrypt hash */
      transaction: /^\\$s0\\$[0-9a-f]{5,6}\\$[a-zA-Z0-9/+]+[=]*\\$[a-zA-Z0-9/+]+[=]*$/,
      address: /[lm3][a-zA-Z0-9]{26,42}|[LM3][a-km-zA-HJ-NP-Z1-9]{26,33}/
    }
  },
]
