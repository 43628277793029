import styled from 'styled-components'

// Library Components
import { Snackbar } from '@material-ui/core'

export const AlertContent = styled(Snackbar)`
  & .MuiPaper-root {
    background-color: #fff !important;
    border-radius: 3px;

    // Variants
    ${({ type, theme }) => type === 'success' && `color: ${theme.colors.success};`}
    ${({ type, theme }) => type === 'success' && `border-left: 5px solid ${theme.colors.success}`}
    ${({ type, theme }) => type === 'warning' && `color: ${theme.colors.warning};`}
    ${({ type, theme }) => type === 'warning' && `border-left: 5px solid ${theme.colors.warning}`}
    ${({ type, theme }) => type === 'error' && `color: ${theme.colors.red};`}
    ${({ type, theme }) => type === 'error' && `border-left: 5px solid ${theme.colors.red}`}
    ${({ type, theme }) => type === 'info' && `color: ${theme.colors.blueGray};`}
    ${({ type, theme }) => type === 'info' && `border-left: 5px solid ${theme.colors.blueGray}`}
  }
`
