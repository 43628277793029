export default (value) => {
  switch (value) {
    case 'BTC': return 'Bitcoin';

    case 'ETH': return 'Ethereum';

    case 'LTC': return 'Litecoin';

    case 'BCH': return 'Bitcoin Cash';

    default:
  }
}
