import mediaQuery from 'common/config/theme/app/functions/mediaQuery'
import * as mixins from 'common/config/theme/app/functions/mixins'

export const colors = {
  blue: '#0c2e85',
  darkBlue: '#1204c9',
  brightBlue: '#2e4ad4',
  blueGray: '#0e039d',
  navyBlue: '#0b037a',
  royalBlue: '#4436eb',
  aliceBlue: '#f8f9fb',
  paleNavy: '#efeefe',
  warning: '#FFB347',
  success: '#3cb371',
  info: '#0275d8',

  mediumPurple: '#7068df',
  lightPurple: '#b7b3ee',

  red: '#fd1e4d',
  brightRed: '#fd4a71',

  lightGrey: '#cbcbcb',
  darkGray: '#606167',
  sandGray: '#525252',
  gray: '#5f6267',
  ash: '#dbdbdb',

  black: '#000',
  lightBlack: '#282828',
  darkGrayishNavy: '#31353e',

  darkishWhite: '#fafafa',
  white: '#ffffff',
}

export const fonts = {
  code: "'Courier New', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Monaco', monospace !default",
  text: "'Open Sans', 'Helvetica Neue Light', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !default",
}

export const functions = {
  mediaQuery,
  mixins,
}

export const layout = {
  sidebar: false,
}
