import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'

// Library Components
import { Layout } from 'antd'

// Store
import { actions } from 'core/store'

// Hooks
import { useFetchCaseCustomer, useFetchCaseAddresses } from 'core/hooks/api'

// Styled Components
import { AddressesWrapper, CaseStatusSummaryWrapper } from './Addresses.elements'

// Views
import { Container, Button } from 'views/components'

import { App, Header, Sidebar, Content, CasesSubHeader, CaseStatusSummary, AddressesTable } from 'views/layouts'

// Map Redux Props
const mapStateToProps = ({ filters, ui }) => ({ filters, ui })
const mapDispatchToProps = actions

const Addresses = (props) => {
  // Destructure
  const { actions, filters, ui } = props
  const { whitelistingFilters } = filters
  const { isPageTableUpdated } = ui

  // Store Actions
  const {
    toggleUploadCaseModal, setActiveModule, setActiveCase, setShowHeaderLoader, setIsPageTableUpdated, showAlert
  } = actions

  // Hooks
  const { customerId } = useParams()
  const { caseCustomerData, getCaseCustomer, isCaseCustomerLoading, isCaseCustomerError } = useFetchCaseCustomer()
  const { caseAddressesData, getCaseAddresses, isCaseAddressesLoading, isCaseAddressesError } = useFetchCaseAddresses()

  // Variables
  const isLoading = (isCaseAddressesLoading || isCaseCustomerLoading)
  const notLoading = (!isCaseAddressesLoading && !isCaseCustomerLoading)
  const isTableUpdated = (caseCustomerData && (!caseAddressesData || isPageTableUpdated || whitelistingFilters))
  const isError = (isCaseAddressesError || isCaseCustomerError)

  // Functions
  const fetchData = () => {
    const { start, end, pageIndex, pageSize, sortBy, sortDirection } = whitelistingFilters
    getCaseAddresses({
      case_id: caseCustomerData.Case_ID,
      start_range: start,
      end_range: end,
      sortby: sortBy,
      sortdir: sortDirection,
      pageindex: pageIndex,
      pagesize: pageSize,
    })
  }

  // useEffect
  useEffect(() => {
    setActiveModule('addresses')
  }, [])
  // fetching data on initial load, or on page table update, or on filter change
  useEffect(() => {
    if (customerId && !caseCustomerData) { return getCaseCustomer({ customer_id: customerId }) }
    if (isTableUpdated) {
      fetchData()
      setActiveCase(caseCustomerData.Case_ID)
      if (isPageTableUpdated) { getCaseCustomer({ customer_id: customerId }); setIsPageTableUpdated(false) }
    }
  }, [customerId, caseCustomerData, isPageTableUpdated, whitelistingFilters])

  useEffect(() => {
    if (isLoading) { return setShowHeaderLoader(true) }
    if (notLoading) { return setShowHeaderLoader(false) }
  }, [isCaseCustomerLoading, isCaseAddressesLoading])

  useEffect(() => {
    if (isError) showAlert({ type: 'error', message: 'An error occured in fetching the data.' })
  }, [isCaseAddressesError, isCaseCustomerError])

  return (
    <App>
      <Sidebar />
      <Layout>
        <Header subHeader={<CasesSubHeader />} />
        <Content>
          <AddressesWrapper>
            <Container>
              <CaseStatusSummaryWrapper>
                {caseCustomerData && (
                  <CaseStatusSummary
                    customerId={caseCustomerData.Customer_ID}
                    total={caseCustomerData.AddressCount}
                    status={caseCustomerData.Case_Status}
                    type="address"
                    caseType={caseCustomerData.Case_Type}
                  />
                )}
              </CaseStatusSummaryWrapper>
            </Container>
            <Container>
              <AddressesTable
                data={caseAddressesData}
                isLoading={isCaseAddressesLoading}
                headerOptions={
                  <Button variant="outlined" onClick={() => { toggleUploadCaseModal() }}>
                    Import
                  </Button>
                }
              />
            </Container>
          </AddressesWrapper>
        </Content>
      </Layout>
    </App>
  )
}

// Default Props
Addresses.defaultProps = {
  actions: {},
  filters: {},
  ui: {}
}

// Proptypes Validation
Addresses.propTypes = {
  actions: PropTypes.shape({
    toggleUploadCaseModal: PropTypes.func,
    setActiveModule: PropTypes.func,
    setActiveCase: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  filters: PropTypes.shape({
    whitelistingFilters: PropTypes.instanceOf(Object)
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(Addresses)
