import styled from 'styled-components'

export const ModalHeaderWrapper = styled.div`
  position: relative;
  border: none;
  padding: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
`

export const ModalHeaderTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
`

export const ModalHeaderCloseIcon = styled.div`
  transition: .4s;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 5px;

  &:hover{
    transform: scale(1.2)
  }
`
