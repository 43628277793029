import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// Assets
import CloseIcon from 'assets/images/x.svg'

// Store
import { actions } from 'core/store'

// Styled Elements
import { ModalFooterWrapper, ModalBodyWrapper, ModalFooterButton } from './DeleteAddressModal.elements'

// Views
import { Modal, ModalHeader, ModalBody, ModalFooter, Image, Button } from 'views/components'

// Hooks
import { useRemoveMonitoringTransaction, useRemoveWhitelistingAddress } from 'core/hooks/api'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = actions

const DeleteAddressModal = (props) => {
  // Destructure
  const { ui, form, actions } = props

  // Store State
  const { isDeleteAddressModalOpen } = ui
  const { activityToRemove } = form

  // Store Actions
  const { toggleDeleteAddressModal, setIsPageTableUpdated, setActivityToRemove, setShowHeaderLoader } = actions

  // Hooks
  const { removeMonitoringTransaction, isRemoveMonitoringTransactionLoading } = useRemoveMonitoringTransaction()
  const { removeWhitelistingAddress, isRemoveWhitelistingAddressLoading } = useRemoveWhitelistingAddress()

  // Functions
  const toggleModal = () => {
    toggleDeleteAddressModal()
  }
  const handleDelete = () => {
    if (activityToRemove.type === 'transaction') {
      removeMonitoringTransaction({ tx_id: activityToRemove.hash })
    } else if (activityToRemove.type === 'address') {
      removeWhitelistingAddress({ address_id: activityToRemove.hash })
    }
  }

  // UseEffect
  useEffect(() => {
    if (isDeleteAddressModalOpen) {
      if (isRemoveMonitoringTransactionLoading || isRemoveWhitelistingAddressLoading) {
        setShowHeaderLoader(true)
      } else if (!isRemoveMonitoringTransactionLoading && !isRemoveWhitelistingAddressLoading) {
        setIsPageTableUpdated(true)
        setActivityToRemove({ type: '', hash: '' })
        toggleDeleteAddressModal()
        setShowHeaderLoader(false)
      }
    }
  }, [isRemoveMonitoringTransactionLoading, isRemoveWhitelistingAddressLoading])
  return (
    <Modal isOpen={isDeleteAddressModalOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal} close={<Image width={20} height={20} src={CloseIcon} onClick={toggleModal} />}>
        Delete address
      </ModalHeader>
      <ModalBody>
        <ModalBodyWrapper>Are you sure you want to delete this address? This cannot be undone.</ModalBodyWrapper>
      </ModalBody>
      <ModalFooter>
        <ModalFooterWrapper>
          <ModalFooterButton onClick={handleDelete}>
            <Button color="secondary">Delete</Button>
          </ModalFooterButton>
          <ModalFooterButton onClick={toggleModal}>
            <Button color="default">Cancel</Button>
          </ModalFooterButton>
        </ModalFooterWrapper>
      </ModalFooter>
    </Modal>
  )
}

// Default Props
DeleteAddressModal.defaultProps = {
  ui: {},
  form: {},
  actions: {},
}

// Proptypes Validation
DeleteAddressModal.propTypes = {
  ui: PropTypes.shape({ isDeleteAddressModalOpen: PropTypes.bool }),
  form: PropTypes.shape({
    activityToRemove: PropTypes.shape({
      type: PropTypes.string,
      hash: PropTypes.string
    })
  }),
  actions: PropTypes.shape({
    toggleDeleteAddressModal: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    setActivityToRemove: PropTypes.func,
    setShowHeaderLoader: PropTypes.func
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAddressModal)
