import styled from 'styled-components'

export const FormInputWrapper = styled.div``

export const FormLabel = styled.label`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.darkGray};
`

export const FormTextAreaInput = styled.textarea`
  padding: 0.7rem 0.75rem;
  font-size: 16px;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  font-weight: 400;
  outline: none;
  color: ${({ theme }) => theme.colors.darkGray};

  border: ${({ theme }) => `1px solid ${theme.colors.lightGrey}`};

  ${({ resize }) => !resize && 'resize: none;'}
`
