import styled from 'styled-components'

export const AssignToCaseFormWrapper = styled.div``

export const FormInputGroupItem = styled.div`
  margin-bottom: 15px;
`

export const FormErrorText = styled.div`
  margin-bottom: 11px;
  font-size: 17px;
  color: ${({ theme }) => theme.colors.red};
`

export const FormTitle = styled.div`
  margin-bottom: 11px;
  font-size: 17px;
  font-weight: 500;

  // Themed Properties
  color: ${({ theme }) => theme.colors.black};
`
