import styled from 'styled-components'

export const CreateIndividualCaseDrawerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 400px;

  // Themed Properties
  background-color: ${({ theme }) => theme.colors.aliceBlue};
`

export const DrawerHeader = styled.div`
  display: flex;
  align-items: center;

  padding: 16px 32px;

  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
`

export const DrawerContent = styled.div`
  flex: 1;

  overflow-y: auto;

  width: 100%;
  padding: 32px;
`

export const DrawerFooter = styled.div`
  display: flex;

  width: 100%;

  padding: 16px 32px;

  border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};
`

export const DrawerCloseIcon = styled.div`
  cursor: pointer;

  // TODO: Use Theme Functions
  &:hover {
    opacity: 0.7;
  }
`

export const DrawerTitle = styled.div`
  margin: 0px 0px 0px 30px;

  font-size: 20px;
  font-weight: 500;

  color: ${({ theme }) => theme.colors.darkGray};
`
