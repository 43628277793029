import React from 'react'
import PropTypes from 'prop-types'

// Views
import { HorizontalBarChart } from 'views/components'

// Styled Elements
import {
  SeverityChartWrapper,
  SeverityChartRowWrapper,
  SeverityChartRowContainer,
  SeverityChartColWrapper,
  SeverityChartTitle,
  SeverityChartText,
} from './SeverityChart.elements'

const SeverityChart = (props) => {
  // Destructure
  const { data } = props
  const { uiData, chartData } = data
  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    layout: {
      padding: {
        left: 60,
        right: 20
      }
    },
    clip: false,
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: true,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            display: false,
            beginAtZero: true,
          }
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            mirror: true,
            padding: 60,
            beginAtZero: true,
          }
        },
      ],
    },
  }

  return (
    <SeverityChartWrapper>
      <SeverityChartTitle>Alert Severity</SeverityChartTitle>
      <SeverityChartRowWrapper>
        <HorizontalBarChart
          data={chartData}
          options={options}
        />
        <SeverityChartRowContainer>
          <SeverityChartColWrapper>
            {uiData && uiData.map((item) => (
              <SeverityChartText key={item.severity}>{item.alert_count}</SeverityChartText>
            ))}
          </SeverityChartColWrapper>
          <SeverityChartColWrapper>
            {uiData && uiData.map((item) => (
              <SeverityChartText key={item.severity}>{item.percent}%</SeverityChartText>
            ))}
          </SeverityChartColWrapper>
        </SeverityChartRowContainer>
      </SeverityChartRowWrapper>
    </SeverityChartWrapper>
  )
}

// Default Props
SeverityChart.defaultProps = {
  data: {
    chartData: {},
    uiData: [
      {
        alert_count: 0,
        severity: 'High',
        percent: 0
      },
      {
        alert_count: 0,
        severity: 'Medium',
        percent: 0
      },
      {
        alert_count: 0,
        severity: 'Low',
        percent: 0
      }
    ]
  },
}

// Proptypes Validation
SeverityChart.propTypes = {
  data: PropTypes.shape({
    chartData: PropTypes.shape({
      datasets: PropTypes.instanceOf(Array), labels: PropTypes.instanceOf(Array)
    }),
    uiData: PropTypes.arrayOf(PropTypes.shape({
      alert_count: PropTypes.number,
      severity: PropTypes.string,
      percent: PropTypes.number
    }))
  }),
}

export default SeverityChart
