import React, { useState } from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import {
  AccordionTableDetailsWrapper,
  AccordionTableDetailsItem,
  AccordionTableDetailsTitle,
  AccordionDetailsRowWrapper,
  AccordionDetailsWrapper,
  AccountDetailsWrapper,
  AccordionDetailsColWrapper,
  LoaderWrapper
} from './AccordionTableDetails.elements'

// Constants
import { TOKEN_HEADERS } from 'common/constants/tokenSampleData'

// Views
import { DataBreakdownTable, DataBreakdownTableScroll, TextArea, LoaderCircle } from 'views/components'

const AccordionTableDetails = (props) => {
  // Destructure
  const {
    data: { itemDetails, totalDetails, accountDetails, token, notes },
    type,
    onTextChange,
    caseLoading,
  } = props

  // State
  const [textValue, setTextValue] = useState(notes)
  const [isNoteLoading, setIsNoteLoading] = useState(false)

  // Variables
  const itemTitle = type === 'address' ? 'Address Details' : 'Transaction Details'

  // Functions
  const handleTextChange = (e) => {
    setTextValue(e.target.value)
    onTextChange(e.target.value, setIsNoteLoading)
    // the loading state must be in the child component for it to re render
    // the state is changed in the parent because debounce isnt working in the child component
  }

  return (
    <AccordionTableDetailsWrapper>

      <AccordionDetailsRowWrapper>
        <AccordionDetailsWrapper>
          <AccordionTableDetailsTitle>{itemTitle}</AccordionTableDetailsTitle>
          <AccordionTableDetailsItem>
            <DataBreakdownTable headers={itemDetails.headers} data={itemDetails.data} />
          </AccordionTableDetailsItem>
          <AccordionTableDetailsItem>
            <DataBreakdownTable headers={totalDetails.headers} data={totalDetails.data} />
          </AccordionTableDetailsItem>
        </AccordionDetailsWrapper>

        <AccountDetailsWrapper>
          {(!caseLoading && accountDetails) &&
            <>
              <AccordionTableDetailsTitle>Account Information</AccordionTableDetailsTitle>
              <AccordionTableDetailsItem>
                <DataBreakdownTable headers={accountDetails.headers} data={accountDetails.data} />
              </AccordionTableDetailsItem>
            </>
          }
          {(caseLoading) &&
            <LoaderWrapper>
              <LoaderCircle />
            </LoaderWrapper>
          }
        </AccountDetailsWrapper>
      </AccordionDetailsRowWrapper>
      {token &&
        <AccordionDetailsColWrapper>
          <AccordionTableDetailsTitle>Token Information</AccordionTableDetailsTitle>
          <DataBreakdownTableScroll headers={TOKEN_HEADERS} data={token} />
        </AccordionDetailsColWrapper>
      }

      <AccordionDetailsColWrapper>
        <AccordionTableDetailsTitle>Note</AccordionTableDetailsTitle>
        <TextArea
          saveLoading={isNoteLoading}
          value={textValue}
          onChange={handleTextChange}
          resize
          rows={4}
          placeholder="Write a note"
        />
      </AccordionDetailsColWrapper>

    </AccordionTableDetailsWrapper>
  )
}

// Default Props
AccordionTableDetails.defaultProps = {
  type: '',
  data: {},
  onTextChange: () => {},
  caseLoading: false,
}

// Proptypes Validation
AccordionTableDetails.propTypes = {
  type: PropTypes.string,
  data: PropTypes.shape({
    itemDetails: PropTypes.shape({ headers: PropTypes.instanceOf(Array), data: PropTypes.instanceOf(Array) }),
    totalDetails: PropTypes.shape({ headers: PropTypes.instanceOf(Array), data: PropTypes.instanceOf(Array) }),
    accountDetails: PropTypes.shape({ headers: PropTypes.instanceOf(Array), data: PropTypes.instanceOf(Array) }),
    token: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Array)
    ]),
    notes: PropTypes.string,
  }),
  onTextChange: PropTypes.func,
  caseLoading: PropTypes.bool,
}

export default AccordionTableDetails
