import styled from 'styled-components'

export const ResetPasswordWrapper = styled.div`
  min-height: 100vh;
`

export const ResetPasswordInnerWrapper = styled.div`
`

export const ResetPasswordContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0px 0px 0px;
`

export const ResetPasswordFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`

export const ResetPasswordFormInnerWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.lightGrey}
`

export const ResetPasswordFormContent = styled.div`
  padding: 54px 41px;
  width: 420px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
`

export const ResetPasswordHeaderWrapper = styled.div`
  justify-content: center;
  padding: 20px 0px 10px 0px;
`

export const ResetPasswordLogoLink = styled.a``

export const ResetPasswordBrandWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  min-height: 100vh;
`

export const ResetPasswordBottomWrapper = styled.div`
  align-self: center;
  color: ${({ theme }) => (theme.colors.white)};
`

export const ResetPasswordFormTitle = styled.h1`
  margin-bottom: 0;

  font-size: 23px;
  font-weight: 800;
  color: ${({ theme }) => (theme.colors.darkGray)};
  text-align: center;
`

export const ResetPasswordFormDescription = styled.p`
  margin: 3px 0px 20px 0px;

  font-size: 16px;
  color: ${({ theme }) => (theme.colors.darkGray)};
  text-align: center;
`
