import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { TextBlurWrapper } from './TextBlur.elements'

const TextBlur = (props) => {
  // Destructure
  const { width, height, color } = props

  return <TextBlurWrapper width={width} height={height} color={color} />
}

// Default Props
TextBlur.defaultProps = {
  width: '',
  height: '',
  color: '',
}

// Proptypes Validation
TextBlur.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.string,
  color: PropTypes.string,
}

export default TextBlur
