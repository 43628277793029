import styled from 'styled-components'

export const CaseManagementTableFilterFormWrapper = styled.div`
  width: 100%;
`

export const FormAutoCompleteWrapper = styled.div`
  width: 100%;

  // Responsive Properties
  ${({ theme }) => theme.functions.mediaQuery('display', ['block', 'block', 'block', 'block', 'block', 'none'])}
`

export const CaseManagementTableFilterInner = styled.div`
  display: flex;
  gap: 10px;
`

export const CaseManagementTableFilterItem = styled.div`
  min-width: 120px;
  width: 100%;
`
