import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import {
  AutoCompleteWrapper,
  Label,
  AutoCompleteInput,
  SelectInputOptionsContainer,
  SelectInputOptions
} from './AutoComplete.elements'

// Function that listens to oustide clicks of given ref
const useOutsideAlerter = (ref, setShowOptions) => {
  useEffect(() => {
      function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setShowOptions(false)
          }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
  }, [ref]);
}

const AutoComplete = (props) => {
  // Destructure
  const {
    label,
    options,
    placeholder,
    onInputChange,
    onSelect,
    isLoading,
    ...rest
  } = props

  // Variables
  const withOptions = options && options?.length > 0
  const wrapperRef = useRef()

  // States
  const [inputValue, setInputValue] = useState('')
  const [showOptions, setShowOptions] = useState(false)

  // Functions
  const handleOnSelect = (value) => {
    setInputValue(value)
    onSelect(value)
    setShowOptions(false)
  }
  const handleOnChange = (e) => {
    setInputValue(e.target.value)
    onInputChange(e.target.value)
  }

  // Closing options on click away
  useOutsideAlerter(wrapperRef, setShowOptions)

  return (
    <AutoCompleteWrapper ref={wrapperRef}>
      {label && <Label>{label}</Label>}
      <AutoCompleteInput
        value={inputValue}
        onChange={handleOnChange}
        placeholder={placeholder}
        onFocus={() => { setShowOptions(true) }}
        type="search"
        autocomplete="off"
        {...rest}
      />
      {showOptions &&
        <SelectInputOptionsContainer>
          {withOptions ?
            options.map(({ value, label }) => (
              <SelectInputOptions
                onClick={() => { handleOnSelect(value) }}
                key={label}
                value={value}
              >
                {label}
              </SelectInputOptions>
            ))
          :
            <>
              {isLoading ?
                'loading...'
              :
                'No results found'
              }
            </>
          }
        </SelectInputOptionsContainer>
      }
    </AutoCompleteWrapper>
  )
}

// Default Props
AutoComplete.defaultProps = {
  label: '',
  placeholder: '',
  options: [],
  onSelect: () => {},
  onInputChange: () => {},
  isLoading: false,
}

// Proptypes Validation
AutoComplete.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.instanceOf(Array),
  onSelect: PropTypes.func,
  onInputChange: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default AutoComplete
