import React from 'react'
// import { ReactQueryDevtools } from 'react-query-devtools'

// App Providers
import AppStoreProvider from 'core/providers/AppStoreProvider'
import AppReactQueryProvider from 'core/providers/AppReactQueryProvider'
import AppThemeProvider from 'core/providers/AppThemeProvider'
import AppMaterialUIThemeProvider from './core/providers/AppMaterialUIThemeProvider'

// App Routes
import AppRoutes from 'core/routing'

// App Global Styles
import GlobalStyle from 'common/config/theme/global'

const App = () => (
  <>
    <AppStoreProvider>
      <AppReactQueryProvider>
        <AppMaterialUIThemeProvider>
          <AppThemeProvider>
            <AppRoutes />
          </AppThemeProvider>
        </AppMaterialUIThemeProvider>
      </AppReactQueryProvider>
    </AppStoreProvider>
    <GlobalStyle />
    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
  </>
)

export default App
