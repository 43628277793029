import styled from 'styled-components'

export const CaseDetailsWrapper = styled.div`
  padding: 150px 0px 0px 0px;
`

export const TabHeaderIcon = styled.span`
  display: inline-block;
  width: 30px;
  height: 30px;
`

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding: 0px 30px;
`
