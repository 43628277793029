import React from 'react'
import PropTypes from 'prop-types'

// Styled Elements
import { ImageWrapper } from './Image.elements'

const Image = (props) => {
  // Destructure
  const { src, alt, width, height, ...rest } = props

  return <ImageWrapper src={src} alt={alt} width={width} height={height} {...rest} />
}

// Default Props
Image.defaultProps = {
  src: null,
  alt: '',
  width: '',
  height: '',
}

// Proptypes Validation
Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default Image
