import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'

import theme from 'common/config/theme/app'

const AppThemeProvider = (props) => {
  // Destructure
  const { children } = props

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default AppThemeProvider

// Proptypes Validation
AppThemeProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]).isRequired,
}
