import styled from 'styled-components'

// Library Components
import { AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion'

export const AccordionItemButtonWrapper = styled(AccordionItemButton)`
  position: relative;
  padding: 0px 30px 0px 30px;
  -webkit-font-smoothing: antialiased;

  // TODO: Use theme functions
  &:focus {
    outline: none;
  }

  // Themed Properties
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
`

export const AccordionItemButtonInnerWrapper = styled.div`
  height: 55px;
  display: flex;
  align-items: center;
`

export const AccordionItemButtonInner = styled.div`
  flex: 1;
  padding: 0px 10px 0px 0px;
  font-size: 15px;
  font-weight: 100;
`

export const AccordionItemButtonExpandIcon = styled.div`
  display: flex;
  height: 15px;
  width: 15px;

  transition: all 0.15s ease-in;

  // Conditional Properties
  ${({ withExpandIcon }) => !withExpandIcon && 'display: none;'}
  ${({ expanded }) => expanded && 'transform: rotate(180deg);'}
`

export const AccordionItemPanelWrapper = styled(AccordionItemPanel)`
  padding: 20px 40px;

  animation: fadein 0.3s ease-in;

  // TODO: Use theme mixins
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  // Themed Properties
  background: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
`
