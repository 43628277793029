import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'

// Library Components
import { Layout } from 'antd'

// Store
import { actions } from 'core/store'

// Hooks
import { useFetchCaseCustomer, useFetchCaseTransactions } from 'core/hooks/api'

// Styled Components
import { TransactionsWrapper, CaseStatusSummaryWrapper } from './Transactions.elements'

// Views
import { Container, Button } from 'views/components'

import { App, Header, Sidebar, Content, CasesSubHeader, CaseStatusSummary, TransactionsTable } from 'views/layouts'

// Map Redux Props
const mapStateToProps = ({ filters, ui }) => ({ filters, ui })
const mapDispatchToProps = actions

const Transactions = (props) => {
  // Destructure
  const { actions, filters, ui } = props
  const { monitoringFilters } = filters
  const { isPageTableUpdated } = ui

  // Store Actions
  const {
    toggleUploadCaseModal, setActiveModule, setActiveCase, setShowHeaderLoader, setIsPageTableUpdated, showAlert
  } = actions

  // Hooks
  const { customerId } = useParams()
  const { caseCustomerData, getCaseCustomer, isCaseCustomerLoading, isCaseCustomerError } = useFetchCaseCustomer()
  const {
    caseTransactionsData, getCaseTransactions, isCaseTransactionsLoading, isCaseTransactionsError
  } = useFetchCaseTransactions()

  // Variables
  const isLoading = (isCaseTransactionsLoading || isCaseCustomerLoading)
  const notLoading = (!isCaseTransactionsLoading && !isCaseCustomerLoading)
  const isTableUpdated = (!!caseCustomerData && (!caseTransactionsData || isPageTableUpdated || monitoringFilters))
  const isError = (isCaseTransactionsError || isCaseCustomerError)

  // Functions
  const fetchData = () => {
    const { start, end, pageIndex, pageSize, sortBy, sortDirection } = monitoringFilters
    getCaseTransactions({
      case_id: caseCustomerData.Case_ID,
      start_range: start,
      end_range: end,
      sortby: sortBy,
      sortdir: sortDirection,
      pageindex: pageIndex,
      pagesize: pageSize,
    })
  }

  // useEffect
  useEffect(() => {
    setActiveModule('transactions')
  }, [])
  // fetching data on initial load, or on page table update, or on filter change
  useEffect(() => {
    if (customerId && !caseCustomerData) { return getCaseCustomer({ customer_id: customerId }) }
    if (isTableUpdated) {
      fetchData()
      setActiveCase(caseCustomerData.Case_ID)
      if (isPageTableUpdated) { getCaseCustomer({ customer_id: customerId }); setIsPageTableUpdated(false) }
    }
  }, [customerId, caseCustomerData, isPageTableUpdated, monitoringFilters])

  useEffect(() => {
    if (isLoading) { return setShowHeaderLoader(true) }
    if (notLoading) { return setShowHeaderLoader(false) }
  }, [isCaseCustomerLoading, isCaseTransactionsLoading])

  useEffect(() => {
    if (isError) showAlert({ type: 'error', message: 'An error occured in fetching the data.' })
  }, [isCaseTransactionsError, isCaseCustomerError])

  return (
    <App>
      <Sidebar />
      <Layout>
        <Header subHeader={<CasesSubHeader />} />
        <Content>
          <TransactionsWrapper>
            <Container>
              <CaseStatusSummaryWrapper>
                {caseCustomerData && (
                  <CaseStatusSummary
                    customerId={caseCustomerData.Customer_ID}
                    total={caseCustomerData.TxCount}
                    status={caseCustomerData.Case_Status}
                    type="transaction"
                    caseType={caseCustomerData.Case_Type}
                  />
                )}
              </CaseStatusSummaryWrapper>
            </Container>
            <Container>
              <TransactionsTable
                data={caseTransactionsData}
                isLoading={isCaseTransactionsLoading}
                headerOptions={
                  <Button variant="outlined" onClick={() => { toggleUploadCaseModal() }}>
                    Import
                  </Button>
                }
              />
            </Container>
          </TransactionsWrapper>
        </Content>
      </Layout>
    </App>
  )
}

// Default Props
Transactions.defaultProps = {
  actions: {},
  filters: {},
  ui: {}
}

// Proptypes Validation
Transactions.propTypes = {
  actions: PropTypes.shape({
    toggleUploadCaseModal: PropTypes.func,
    setActiveModule: PropTypes.func,
    setActiveCase: PropTypes.func,
    setShowHeaderLoader: PropTypes.func,
    setIsPageTableUpdated: PropTypes.func,
    showAlert: PropTypes.func,
  }),
  filters: PropTypes.shape({
    monitoringFilters: PropTypes.instanceOf(Object)
  }),
  ui: PropTypes.shape({
    isPageTableUpdated: PropTypes.bool,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(Transactions)
