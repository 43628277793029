import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useField, ErrorMessage } from 'formik'

// Styled Elements
import {
  SelectWrapper,
  Label,
  FormGroupWrapper,
  SelectInput,
  SelectInputLabel,
  SelectInputOptionsContainer,
  SelectInputOptions,
  FormErrorMessage
} from './FormSelect.elements'

// Function that listens to oustide clicks of given ref
const useOutsideAlerter = (ref, setShowOptions) => {
  useEffect(() => {
      function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setShowOptions(false)
          }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
  }, [ref]);
}

const FormSelect = (props) => {
  // Destructure
  const { label, placeholder, options, disabled, ...rest } = props

  const [fields] = useField(props)

  // Variables
  const withOptions = options && options?.length > 0
  const wrapperRef = useRef()
  const inputRef = useRef(null)
  const { name, value, onChange } = fields

  // Hooks
  const [formValue, setValue] = useState('')
  const [valueLabel, setValueLabel] = useState(placeholder || '')
  const [showOptions, setShowOptions] = useState(false)

  // Functions
  const handleOnChange = async (elValue, elLabel) => {
    await setValue(elValue)
    if (elValue === undefined) {
      await setValueLabel(placeholder || '')
      return setShowOptions(false)
    }
    await setValueLabel(elLabel)
    setShowOptions(false)
    // manually calling onchange event of formik input through onClick
    inputRef.current.click()
  }

  // UseEffects
  useEffect(() => {
    // setting the value and label on initial load
    if (value !== undefined && value !== null) {
      setValue(value)
      const currentLabel = options.find((data) => {
        if (typeof data.value === 'number') {
          return data.value === Number(value)
        }
        return data.value === value
      })
      if (currentLabel?.label) {
        setValueLabel(currentLabel.label)
      } else if (!currentLabel?.label) {
        setValueLabel(placeholder || '')
      }
    }
  }, [value])

  // Closing options on click away
  useOutsideAlerter(wrapperRef, setShowOptions)

  return (
    <SelectWrapper ref={wrapperRef}>
      {label && <Label>{label}</Label>}
      <FormGroupWrapper>
        <SelectInput
          ref={inputRef}
          onClick={(e) => { onChange(e) }}
          name={name}
          onChange={() => {}}
          value={formValue}
          {...rest}
        />
        <SelectInputLabel
          disabled={disabled}
          onClick={() => { if (!disabled) { setShowOptions((prevState) => !prevState) } }}
        >
          {valueLabel !== undefined ? valueLabel : placeholder}
        </SelectInputLabel>
        {showOptions &&
          <SelectInputOptionsContainer>
            {withOptions &&
              options.map(({ value, label }) => (
                <SelectInputOptions
                  onClick={() => { handleOnChange(value, label) }}
                  key={label}
                  value={value}
                >
                  {label}
                </SelectInputOptions>
              ))}
          </SelectInputOptionsContainer>
        }
        <FormErrorMessage>
          <ErrorMessage {...fields} />
        </FormErrorMessage>
      </FormGroupWrapper>
    </SelectWrapper>
  )
}

// Default Props
FormSelect.defaultProps = {
  label: '',
  placeholder: '',
  options: [],
}

// Proptypes Validation
FormSelect.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.instanceOf(Array),
}

export default FormSelect
