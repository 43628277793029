import { authAxios } from 'common/utils/axios'

// Endpoint
import { MONITORING_ENDPOINTS, BASE_URL } from 'common/constants/apiUrls'

export const AssignMonitoringTransaction = async ({ addressOrTransactionId, case_id }) => {
  const response = await authAxios.put(MONITORING_ENDPOINTS.ASSIGN_TRANSACTION, {
    tx_id: addressOrTransactionId,
    case_id,
  })

  const data = response.data.content[0]

  return data
}

export const GetMonitoringTransactionCount = async ({ biz_id, start_range, end_range }) => {
  const response = await authAxios.get(MONITORING_ENDPOINTS.TRANSACTION_COUNT, {
    params: {
      biz_id,
      start_range,
      end_range,
    },
  })

  const data = response.data.content[0]

  return data
}

export const GetMonitoringTransactionHistory = async ({ biz_id, start_range, end_range }) => {
  const response = await authAxios.get(MONITORING_ENDPOINTS.TRANSACTION_HISTORY, {
    params: {
      biz_id,
      start_range,
      end_range,
    },
  })

  const data = response.data.content

  return data
}

export const GetMonitoringTransactionReport = async ({ transaction_id }) => {
  const response = await authAxios.get(
    `${MONITORING_ENDPOINTS.GET_TRANSACTION_REPORT}?transaction_id=${transaction_id}`
  )
  if (response.status === 200) {
    return `${BASE_URL}${MONITORING_ENDPOINTS.GET_TRANSACTION_REPORT}?transaction_id=${transaction_id}`
  }
  if (response.status !== 200) {
    return null
  }
}

export const GetMonitoringPagedTable = async ({
  biz_id,
  start_range,
  end_range,
  sortby,
  sortdir,
  pageindex,
  pagesize,
}) => {
  const response = await authAxios.get(MONITORING_ENDPOINTS.PAGED_TABLE_DATA, {
    params: {
      biz_id,
      start_range,
      end_range,
      sortby,
      sortdir,
      pageindex,
      pagesize,
    },
  })

  const data = response.data.content

  return data
}

export const GetMonitoringRiskBucket = async ({ biz_id, start_range, end_range }) => {
  const response = await authAxios.get(MONITORING_ENDPOINTS.RISK_BUCKET, {
    params: {
      biz_id,
      start_range,
      end_range,
    },
  })

  const data = response.data.content

  return data
}

export const GetMonitoringSearchTransaction = async ({ user_id, transaction, chain }) => {
  const response = await authAxios.get(MONITORING_ENDPOINTS.SEARCH_TRANSACTION, {
    params: {
      user_id,
      transaction,
      chain
    },
  })

  const data = response.data.content[0]

  return data
}

export const PatchMonitoringTransactionNotes = async ({ transaction_id, notes }) => {
  const response = await authAxios.patch(MONITORING_ENDPOINTS.UPDATE_TRANSACTION_NOTES, {
    transaction_id,
    notes
  })

  const data = response.data.content[0]

  return data
}

export const RemoveMonitoringTransaction = async ({ tx_id }) => {
  const response = await authAxios.put(MONITORING_ENDPOINTS.REMOVE_TRANSACTION, {
    tx_id,
  })

  const data = response.data.content[0]

  return data
}
