import { useMutation } from 'react-query'

// Services
import { GetSearchCustomer } from 'services/api/Customer'

export default () => {
  const {
    mutate: getCustomer,
    data: customerData,
    error: customerError,
    isLoading: isCustomerLoading,
    isSuccess: isCustomerSuccess,
    isError: isCustomerError,
  } = useMutation(GetSearchCustomer)

  return {
    getCustomer,
    customerData,
    customerError,
    isCustomerLoading,
    isCustomerSuccess,
    isCustomerError,
  }
}
